import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	token:any;
	page:any;
	profile:any;
	constructor(private router:Router, private route:ActivatedRoute, private auth:AuthenticationService, private spinner: NgxSpinnerService){}

	ngOnInit(): void {
		this.spinner.show();
		this.route.params.pipe().subscribe(params => {
			this.token = params['token'];
			this.page = params['page'];
			if(this.page == 'dashboard'){
				this.page = '';
			}
			localStorage.setItem('token', this.token);
		});
		this.auth.getAPI('profile/').subscribe(result => {
			this.profile = result['results'][0];
			localStorage.setItem('profile', JSON.stringify(this.profile) );
			if (this.page != '') {
				this.router.navigate([this.page]);
			}else{
				this.router.navigate(['']);
			}
		});
	}

}
