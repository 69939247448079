import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cad',
  templateUrl: './cad.component.html',
  styleUrls: ['./cad.component.scss']
})
export class CadComponent implements OnInit {
  sorder = 0;
  norder:any;
  maintenance:boolean = false;
  maintdata:any = {'title':'','message':''}
  constructor(){}

  ngOnInit(): void {}

}
