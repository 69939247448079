<main>
	<app-topnav></app-topnav>
	<section class="container maintenance" *ngIf="maintenance">
		<div><img src="assets/images/503.png" alt="MyClearAmber"></div>
		<h1>{{maintdata.title}}</h1>
		<h3>{{maintdata.message}}</h3>
	</section>
	<section class="container dashboard" *ngIf="!maintenance">
		<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner>
		
		<div class="graphs">
			<div class="row">
				<div class="col-md-2">
					<div class="graph" [ngClass]="{'active':status == 'Received'}" (click)="filterStatus('Received')">
						<h4>RECEIVED</h4>
						<div><app-pie [graph]="graph.received" [who]="'received'"></app-pie></div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="graph" [ngClass]="{'active':status == 'Production'}" (click)="filterStatus('Production')">
						<h4>PRODUCTION</h4>
						<div><app-pie [graph]="graph.production" [who]="'production'"></app-pie></div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="graph" [ngClass]="{'active':status == 'Despatch'}" (click)="filterStatus('Despatch')">
						<h4>DESPATCHED</h4>
						<div><app-pie [graph]="graph.despatch" [who]="'despatch'"></app-pie></div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="graph" [ngClass]="{'active':status == 'Distribution'}" (click)="filterStatus('Distribution')">
						<h4>DISTRIBUTION</h4>
						<div><app-pie [graph]="graph.distribution" [who]="'distribution'"></app-pie></div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="graph" [ngClass]="{'active':status == 'On Its Way'}"(click)="filterStatus('On Its Way')">
						<h4>OUT FOR DELIVERY</h4>
						<div><app-pie [graph]="graph.onway" [who]="'onway'"></app-pie></div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="graph" [ngClass]="{'active':status == 'Delivered'}" (click)="filterStatus('Delivered')">
						<h4>DELIVERED</h4>
						<div><app-pie [graph]="graph.delivered" [who]="'delivered'"></app-pie></div>
					</div>
				</div>
			</div>
		</div>
		<div class="orders">
			<div class="row">
				<div class="col-md-4">
					<mat-form-field appearance="fill">
					  <mat-label>Enter a date range</mat-label>
					  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
						<input matStartDate formControlName="start" placeholder="Start date">
						<input matEndDate formControlName="end" placeholder="End date" (dateChange)="dateChange($event)">
					  </mat-date-range-input>
					  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					  <mat-date-range-picker #picker></mat-date-range-picker>

					  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
					  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
					</mat-form-field>

					<!-- <p>Selected range: {{range.value | json}}</p> -->
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline">
					  <mat-label>FAST SEARCH</mat-label>
					  <input matInput [(ngModel)]="skey" (keyup.enter)="applyFilter($any($event.target).value)" placeholder="Fast search..." #input>
					  <!-- <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Fast search..." #input> -->
					  <!-- <input matInput (keyup)="applyFilter($event)" placeholder="Fast search..." #input> -->
					  <mat-icon matSuffix (click)="fsearch()">search</mat-icon>
					</mat-form-field>
				</div>
			</div>

			<div class="mat-elevation-z8-ca table-responsive">
			  <table mat-table [dataSource]="dataSource" matSort class="table-bordered" summary="Orders list">

				<!-- DISTRIBUTOR CODE Column -->
				<ng-container matColumnDef="companies">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> DISTRIBUTOR CODE </th>
				  <td mat-cell *matCellDef="let row" [ngClass]="{ 'swift': row.swift == 'SWIFT', 'swiftpl': row.swift == 'SWIFT PLUS' }"> <a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank">{{row.companies.account}}</a> </td>
				</ng-container>

				<!-- TRACKING NO. Column -->
				<ng-container matColumnDef="sale">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> TRACKING NO. </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.sale}} </a></td>
				</ng-container>

				<!-- DISTRIBUTOR REF Column -->
				<ng-container matColumnDef="reference">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> DISTRIBUTOR REF </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.reference}} </a></td>
				</ng-container>

				<!-- POST CODE Column -->
				<ng-container matColumnDef="postcode">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> POST CODE </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.postcode}} </a></td>
				</ng-container>

				<!-- POST CODE Column -->
				<ng-container matColumnDef="name">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> NAME </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.name}} </a></td>
				</ng-container>

				<!-- POST CODE Column -->
				<ng-container matColumnDef="email">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> EMAIL </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.email}} </a></td>
				</ng-container>

				<!-- POST CODE Column -->
				<ng-container matColumnDef="mobile">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> MOBILE </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.mobile}} </a></td>
				</ng-container>

				<!-- POST CODE Column -->
				<ng-container matColumnDef="date">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> DATE </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.date}} </a></td>
				</ng-container>

				<!-- POST CODE Column -->
				<ng-container matColumnDef="status">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> STATUS </th>
				  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> {{row.status}} </a></td>
				</ng-container>

				<!-- POST CODE Column -->
				<!-- <ng-container matColumnDef="comments">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> COMMENTS </th>
				  <td mat-cell *matCellDef="let row"> {{row.comments}} </td>
				</ng-container> -->

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
				  <td class="mat-cell" colspan="10">No data matching the filter "{{input.value}}"</td>
				</tr>
			  </table>

			  <mat-paginator [pageSizeOptions]="[50, 100, 200, 500, 1000, 1500, 2000]"></mat-paginator>
			</div>
		</div>
	</section>
</main>