<main>
	<app-topnav></app-topnav>
	<section class="container maintenance" *ngIf="maintenance">
		<div><img src="assets/images/503.png" alt="MyClearAmber"></div>
		<h1>{{maintdata.title}}</h1>
		<h3>{{maintdata.message}}</h3>
	</section>
	<section class="container cadrkdata" *ngIf="!maintenance">
		<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
		
		<div class="filtration" style="margin-top:10px;">
			<!-- <div class="row">
				<div class="col-md-2"><button mat-raised-button class="btn-default">Update Time</button></div>
				<div class="col-md-2"></div>
				<div class="col-md-2"><button mat-raised-button class="btn-default">System Time</button></div>
				<div class="col-md-6"></div>
			</div> -->
			<div class="row" *ngIf="user.cadrk_edit">
				<div class="col-md-2">
					<mat-form-field appearance="outline" class="w100">
					    <mat-label>Update Time</mat-label>
					    <input type="time" [(ngModel)]="uptime" matInput placeholder="Update Time" value="14:41">
					</mat-form-field>
				</div>
				<div class="col-md-2"><button mat-raised-button color="warn" class="fbtn" (click)="utime()">Updated Time</button></div>
				<div class="col-md-2">
					<mat-form-field appearance="outline" class="w100">
					    <mat-label>System Time</mat-label>
					    <input matInput placeholder="System Time" value="{{now | date:'HH:mm:ss'}}" readonly>
					</mat-form-field>
				</div>
				<div class="col-md-6"></div>
			</div>
			<div class="row">
				<div class="col-md-3">
					<mat-form-field appearance="outline" class="w100">
						<mat-label>Area:</mat-label>
						<mat-select [(ngModel)]="area" (selectionChange)="areaFilter()">
							<mat-option [value]="''">-- SELECT AREA --</mat-option>
							<mat-option *ngFor="let area of areas" [value]="area">{{area.title}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field appearance="outline">
		                <mat-label>Despatch Date:</mat-label>
		                <input matInput [matDatepicker]="picker" [(ngModel)]='date' (dateChange)="dchange($event)">
		                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		                <mat-datepicker #picker></mat-datepicker>
		            </mat-form-field>
				</div>
				<div class="col-md-6">
					<div>
						<button mat-raised-button (click)='addCon()' class="fbtn" *ngIf="!user.cadrk_view && !user.cadrk_view_only">Add Con</button> 
						<!-- <button mat-raised-button (click)='upload()' class="fbtn">Upload Manifest</button>  -->
						<input id="file-id" type="file"(change)="upload($event)" style="display:none" />
	        			<label for="file-id" class="mat-focus-indicator md-button md-raised md-primary mat-raised-button mat-button-base" *ngIf="!user.cadrk_view && !user.cadrk_view_only && !user.cadrk_edit_van_only">Upload Manifest</label>
						<button mat-raised-button (click)='bulkEdit()' color="warn" class="fbtn" *ngIf="!user.cadrk_view && !user.cadrk_view_only && !user.cadrk_edit_van_only">Bulk Edit</button> 
						<button mat-raised-button (click)='bulkDelete()' color="warn" class="fbtn" *ngIf="!user.cadrk_view && !user.cadrk_view_only && !user.cadrk_edit_van_only">Bulk Delete</button>
						<!-- <button mat-raised-button (click)='export()' color="warn" class="fbtn" *ngIf="!user.cadrk_view && !user.cadrk_view_only && !user.cadrk_edit_van_only">Export</button> -->
						<a href="{{url}}rkdata-export/?despatch={{today}}&area={{area?.id}}&van={{van}}" mat-raised-button color="warn" class="fbtn" *ngIf="!user.cadrk_view && !user.cadrk_view_only">Export</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="container-fluid cadrkdata">
		<div class="rkdata">
			<div class="row">
				<div class="col-md-8">
					<div class="row">
						<div class="col-md-2">
							<div style="margin-top:15px;">
								<p>No. of CONS From Today <strong>{{total-forward}}</strong></p>
								<p style="margin-top: 35px;">No. of CONS B/Fwd <strong>{{forward}}</strong></p>
							</div>
						</div>
						<div class="col-md-2">
							<div style="margin-top:15px;">
								<p>No. Scanned To Van For TOTAL <strong>{{scanned}}</strong></p>
								<p style="margin-top: 35px;">% Scanned to Van For TOTAL <strong>{{scannedp}}%</strong></p>
							</div>
						</div>
						<div class="col-md-2">
							<div style="margin-top:15px;">
								<p>No. Scanned Delivered For TOTAL <strong>{{delivered}}</strong></p>
								<p style="margin-top: 35px;">% Scanned Delivered For TOTAL <strong>{{deliveredp}}%</strong></p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-4">No. Scan Delivered 1 Days From Today</div>
								<div class="col-md-4">No. Scan Delivered 2 Days From Today</div>
								<div class="col-md-4">No. Scan Delivered 3+ Days From Today</div>
							</div>
							<div class="row">
								<div class="col-md-4"><p class="green">{{day1}}</p></div>
								<div class="col-md-4"><p class="orange">{{day2}}</p></div>
								<div class="col-md-4"><p class="red">{{day3}}</p></div>
							</div>
							<div class="row">
								<div class="col-md-4">% Scan Delivered 1 Days From Today</div>
								<div class="col-md-4">% Scan Delivered 2 Days From Today</div>
								<div class="col-md-4">% Scan Delivered 3+ Days From Today</div>
							</div>
							<div class="row">
								<div class="col-md-4"><p class="green">{{day1p}}%</p></div>
								<div class="col-md-4"><p class="orange">{{day2p}}%</p></div>
								<div class="col-md-4"><p class="red">{{day3p}}%</p></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div style="font-size:14px">Total CONS To Be Delivered Today</div>
							<div style="font-size:50px;margin: 20px 0;font-weight: bold;">{{rkcpr.vanallot}}</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div style="font-size:14px">No of Vans Being Used Today</div>
							<div style="font-size:50px;margin: 20px 0;font-weight: bold;">{{rkcpr.vancount}}</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-8">
							<!-- <highcharts-chart 
				            [Highcharts]="highcharts"
				            [options]="chartOptions"
				            style="width: 100%; height: 200px; display: block;"
				            ></highcharts-chart> -->
				            <div id="cprChart" style="border: 1px solid #F38E2C;"></div>
						</div>
						<div class="col-md-4" style="font-weight: bold;">
							<div style="padding:20px 0;text-align: center;margin: 30px auto;font-size: 80px;">CPR</div>
							<div style="padding:20px 0;text-align: center;background-color: {{rkcpr.cprcolour}};">
								<p style="font-size: 100px; padding: 30px 0;font-size: 100px;">{{rkcpr.cpr}}</p>
								<p style="font-size: 35px;">{{rkcpr.word}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="orders">

			<div class="mat-elevation-z8-ca table-responsive" style="margin-top:10px;font-weight: bold;height: 800px;">
			  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="SortChange($event)" class="table-bordered" summary="Orders list">

				<!-- Checkbox Column -->
		        <ng-container matColumnDef="select">
		          <th mat-header-cell *matHeaderCellDef style="padding-left: 25px;">
		            <mat-checkbox (change)="$event ? toggleAllRows() : null"
		                          [checked]="selection.hasValue() && isAllSelected()"
		                          [indeterminate]="selection.hasValue() && !isAllSelected()"
		                          [aria-label]="checkboxLabel()" *ngIf="!user.cadrk_view && !user.cadrk_view_only && !user.cadrk_edit_van_only">
		            </mat-checkbox>
		          </th>
		          <td mat-cell *matCellDef="let row">
		            <mat-checkbox (click)="$event.stopPropagation()"
		                          (change)="$event ? selection.toggle(row) : null"
		                          [checked]="selection.isSelected(row)"
		                          [aria-label]="checkboxLabel(row)" *ngIf="!user.cadrk_view && !user.cadrk_view_only && !user.cadrk_edit_van_only">
		            </mat-checkbox>
		          </td>
		        </ng-container>
		        <!-- DISTRIBUTOR CODE Column -->
				<ng-container matColumnDef="area">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">AREA</th>
				  <td mat-cell *matCellDef="let row">{{row.areas.title}}</td>
				</ng-container>

				<!-- TRACKING NO. Column -->
				<ng-container matColumnDef="sale">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CON</th>
				  <!-- <td mat-cell *matCellDef="let row"> {{row.sale}} </td> -->
				  <td mat-cell *matCellDef="let row">
				  	<a href="{{auth.baseUrl}}/pod/{{row.orders?.identifier}}/" target="_blank" *ngIf="user.cadrk_edit"> {{row.sale}} </a>
				  	<p *ngIf="!user.cadrk_edit">{{row.sale}}</p>
				  </td>
				</ng-container>

				<ng-container matColumnDef="despatch">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">DESPATCH DATE</th>
				  <td mat-cell *matCellDef="let row">{{row.display}}</td>
				  <!-- <td mat-cell *matCellDef="let row">{{row.despatch}}</td> -->
				</ng-container>

				<ng-container matColumnDef="delivery">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">EST DEL DATE</th>
				  <td mat-cell *matCellDef="let row">
				  	<!-- {{row.delivery}} -->
				  	<mat-form-field appearance="outline" style="width: 100px;" [ngClass]="{'redbg2':row.delivery == null || row.deadline > 2 || row.parent != null, 'amberbg':row.deadline == 2}">
		                <mat-label>EST DEL DATE</mat-label>
		                <input matInput [matDatepicker]="picker" [(ngModel)]='row.delivery' (dateChange)="dupdate($event,row)">
		                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		                <mat-datepicker #picker></mat-datepicker>
		            </mat-form-field>
				  </td>
				</ng-container>

				<ng-container matColumnDef="surname">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">SURNAME</th>
				  <td mat-cell *matCellDef="let row">{{row.orders?.surname}}</td>
				</ng-container>

				<ng-container matColumnDef="w3w">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">W3W</th>
				  <td mat-cell *matCellDef="let row">{{row.orders?.w3w}}</td>
				</ng-container>

				<ng-container matColumnDef="image">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Image</th>
				  <td mat-cell *matCellDef="let row">
				  	<a href="{{auth.baseUrl}}/pod/{{row.orders?.identifier}}/" target="_blank" *ngIf="row.orders?.image1 != null"><img src="{{row.orders?.image1}}" alt="POD" style="max-width:70px;max-height:70px;"></a>
				  </td>
				</ng-container>

				<!-- CUSTOMER Column -->
				<ng-container matColumnDef="customer">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CUSTOMER</th>
				  <td mat-cell *matCellDef="let row">{{row.customer}}</td>
				</ng-container>

				<!-- ADDRESS Column -->
				<ng-container matColumnDef="address">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">ADDRESS</th>
				  <td mat-cell *matCellDef="let row" style="text-align: left;max-width: 95px;overflow: hidden;">
				  	<mat-form-field appearance="outline" *ngIf="!user.cadrk_view && !user.cadrk_view_only && (user.cadrk_edit_van_only || user.cadrk_edit_only || user.cadrk_edit)" class="w100">
				  		<textarea [(ngModel)]="row.address" matInput placeholder="Address..." rows="3" (focusout)="focusOutAddress(row)"></textarea>
					  	<!-- <input matInput placeholder="Address" [(ngModel)]="row.address" (focusout)="focusOutAddress(row)"> -->
					</mat-form-field>
					<p *ngIf="(user.cadrk_view || user.cadrk_view_only || !user.cadrk_edit_van_only) && !user.cadrk_edit_only && !user.cadrk_edit">{{row.address}}</p>
				  </td>
				</ng-container>

				<!-- POST CODE Column -->
				<!-- <ng-container matColumnDef="town">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> TOWN </th>
				  <td mat-cell *matCellDef="let row">{{row.town}}</td>
				</ng-container> -->

				<!-- POST CODE Column -->
				<ng-container matColumnDef="postcode">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">POSTCODE</th>
				  <td mat-cell *matCellDef="let row">{{row.postcode}}</td>
				</ng-container>

				<!-- WEIGHT Column -->
				<ng-container matColumnDef="weight">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">WEIGHT (KG)</th>
				  <td mat-cell *matCellDef="let row">{{row.weight}}</td>
				</ng-container>

				<!-- TOTAL PARCELS Column -->
				<ng-container matColumnDef="parcel">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 60px;">TOTAL PARCELS</th>
				  <td mat-cell *matCellDef="let row">{{row.parcel}}</td>
				</ng-container>

				<!-- PARCELS LOADED Column -->
				<ng-container matColumnDef="loaded">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 60px;">PARCELS LOADED</th>
				  <td mat-cell *matCellDef="let row">
				  	<p [ngClass]="{'redbg':row.loaded > 0}">{{row.loaded}}</p>
				  </td>
				</ng-container>

				<!-- OVER 4M Column -->
				<ng-container matColumnDef="over">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">OVER 4M?</th>
				  <td mat-cell *matCellDef="let row">
				  	<span *ngIf='row.over'>Yes</span>
				  	<span *ngIf='!row.over'>No</span>
				  </td>
				</ng-container>

				<!-- SCAN TO VAN Column -->
				<ng-container matColumnDef="scanned">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">SCAN TO VAN</th>
				  <td mat-cell *matCellDef="let row">
				  	<p [ngClass]="{'yellowbg':row.scanned == true}">
				  		<span *ngIf='row.scanned'>Yes</span>
				  		<span *ngIf='!row.scanned'>No</span>
				  	</p>
				  </td>
				</ng-container>

				<!-- DELIVERED Column -->
				<ng-container matColumnDef="delivered">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">DELIVERED</th>
				  <td mat-cell *matCellDef="let row">
				  	<p [ngClass]="{'greenbg':row.delivered == true}">
				  		<span *ngIf='row.delivered'>Yes</span>
				  		<span *ngIf='!row.delivered'>No</span>
				  	</p>
				  </td>
				</ng-container>

				<!-- VAN NO Column -->
				<ng-container matColumnDef="vans">
				  <th mat-header-cell *matHeaderCellDef scope="col">
				  	<p>VAN NO.</p>
				  	<p>
				  		<mat-form-field appearance="outline">
						  	<input matInput placeholder="VAN No." [(ngModel)]="van" (keyup)="vanFilter()">
						</mat-form-field>
				  		<!-- <mat-form-field appearance="outline" class="w100" style="color: white;">
							<mat-select [(ngModel)]="van" (selectionChange)="vanFilter()" placeholder="ALL">
								<mat-option [value]="''">-- ALL --</mat-option>
								<mat-option *ngFor="let vn of vans" [value]="vn">{{vn.title}}</mat-option>
							</mat-select>
						</mat-form-field> -->
				  	</p>
				  </th>
				  <td mat-cell *matCellDef="let row">
				  	<mat-form-field appearance="outline" *ngIf="!user.cadrk_view && !user.cadrk_view_only && (user.cadrk_edit_van_only || user.cadrk_edit_only || user.cadrk_edit)">
					  	<input matInput placeholder="VAN No." [(ngModel)]="row.vans.title" (focusout)="focusOutVan(row)">
					</mat-form-field>
					<p *ngIf="(user.cadrk_view || user.cadrk_view_only || !user.cadrk_edit_van_only) && !user.cadrk_edit_only && !user.cadrk_edit">{{row.vans?.title}}</p>
				  </td>
				</ng-container>

				<!-- POST CODE Column -->
				<ng-container matColumnDef="hrw">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">2HRW</th>
				  <td mat-cell *matCellDef="let row" style="width:120px">
				  	<mat-form-field appearance="outline" *ngIf="!user.cadrk_view && !user.cadrk_view_only && (user.cadrk_edit_van_only || user.cadrk_edit_only || user.cadrk_edit)" style="width:80px" >
					  	<input matInput type="time" placeholder="2HRW" [(ngModel)]="row.hrw" (keyup)="row.saveHr=true">
					</mat-form-field>
					<button mat-button color="primary" *ngIf='row.saveHr' style="width:35px" (click)="hrwupdate(row)">
				        <mat-icon>done</mat-icon>
				    </button>
					<p *ngIf="(user.cadrk_view || user.cadrk_view_only || !user.cadrk_edit_van_only) && !user.cadrk_edit_only && !user.cadrk_edit">{{row.hrw}}</p>
				</td>
				</ng-container>

				<!-- POST CODE Column -->
				<!-- <ng-container matColumnDef="comments">
				  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> COMMENTS </th>
				  <td mat-cell *matCellDef="let row"> {{row.comments}} </td>
				</ng-container> -->

				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'redclr':row.vans?.title?.length == 0 }"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
				  <td class="mat-cell" colspan="16">No data matching the filter</td>
				</tr>
			  </table>

			  <mat-paginator [pageSizeOptions]="[150, 200, 500, 1000, 1500, 2000]"></mat-paginator>
			</div>
		</div>
	</section>
</main>