import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteComponent implements OnInit {

  statusData:any
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<DeleteComponent>) {
    this.statusData=data;
  }

  ngOnInit(): void {}

  reply(status:any){
    this.dialogRef.close({'status':status,'data':this.statusData.data});
  }

  dialogclose(){
    this.dialogRef.close();
  }

}
