import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../core/services/authentication.service'

@Component({
  selector: 'app-cad-form',
  templateUrl: './cad-form.component.html',
  styleUrls: ['./cad-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CadFormComponent implements OnInit {
  user:any = {'id':0, 'username':''};
  areas:any =[];
  vans:any =[];
  cadform = {'id':0, 'area':'', 'van':'', 'sale':0, 'despatch':'', 'customer':'', 'address':'', 'town':'', 'postcode':'', 'weight':0, 'parcel':0, 'loaded':0, 'over':0, 'note':'', 'scanned':false, 'delivered':false, 'order':0}

  constructor(@Inject(MAT_DIALOG_DATA) public data:any, private auth:AuthenticationService, private dailogRef:MatDialogRef<CadFormComponent>, private spinner: NgxSpinnerService, private _snackBar: MatSnackBar){
    this.areas = data['areas'];
    this.vans = data['vans'];
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('profile'));
  }

  add(){
    this.auth.postAPI('rkdata/',this.cadform).subscribe(result => {
      this._snackBar.open("CAD RK Data added successfully",'', {duration: 5000, panelClass: ['blue-snackbar']});
      this.dailogRef.close();
      this.spinner.hide();
    },
    error => {
      if (error.status==400) {
        this._snackBar.open("Please Fill the correct Data",'', {duration: 5000, panelClass: ['blue-snackbar']});
      }
    });
  }

  dchange(event:any){
    this.cadform.despatch = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }
}
