import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { formatDate } from '@angular/common';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthenticationService } from '../../core/services/authentication.service';

export interface OrderData {
  id: string;
  area: any;
  sale: string;
  despatch: string;
  myca: string;
  delivery: string;
  customer: string;
  address: string;
  town: string;
  postcode: string;
  weight: string;
  parcel: string;
  loaded: string;
  over: string;
  hrw: string;
  w3w: string;
  surname: string;
  image: string;
  scanned: string;
  delivered: string;
  vans: any;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit{

  displayedColumns: string[] = ['select', 'area', 'sale','myca', 'despatch', 'surname', 'w3w', 'customer', 'address', 'postcode', 'weight', 'parcel', 'loaded'];
  dataSource: MatTableDataSource<OrderData>;
  selection = new SelectionModel<OrderData>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  user:any = {'id':0, 'username':''};
  status:string='';
  fstatus:string='';
  durl:string='';
  purl:string='';
  orders:any=[];
  companies:any=[];
  company:any;
  dtfilter:boolean=false;
  sdate:any = new Date();
  edate:any = new Date();
  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  constructor(public auth:AuthenticationService, private _liveAnnouncer: LiveAnnouncer,private spinner: NgxSpinnerService, private _snackBar: MatSnackBar){
    this.durl = this.auth.apiUrl+'download/?';
    this.purl = this.auth.apiUrl+'download-pod/?';
    this.user = JSON.parse(localStorage.getItem('profile'));
    this.orderCall();

    this.auth.getAPI('company/?limit=400').subscribe(result => {
      this.companies = result['results'];
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    });

  }

  selectCmp(event){
    // console.log(event.option.value,this.myControl)
    this.myControl.setValue(event.option.value.account);
    this.company = event.option.value;
    this.orderCall(event.option.value.id);
    this.download();
  }

  dchange(event){
    this.sdate = formatDate(this.sdate, 'yyyy-MM-dd', 'en');
    this.edate = formatDate(this.edate, 'yyyy-MM-dd', 'en');
    this.dtfilter = true;
    this.orderCall();
    this.download();
  }

  statusFilter(){
    this.orderCall();
    this.download();
  }

  download(){
    this.durl = this.auth.apiUrl+'download/?status='+this.fstatus+'&';
    this.purl = this.auth.apiUrl+'download-pod/?status='+this.fstatus+'&';
    if(this.company != undefined){
      this.durl = this.durl+'company='+this.company.id;
      this.purl = this.purl+'company='+this.company.id;
    }
    if(this.dtfilter){
      this.durl = this.durl+'&start='+this.sdate+'&end='+this.edate;
      this.purl = this.purl+'&start='+this.sdate+'&end='+this.edate;
    }
  }

  upload(file:any){
    this.spinner.show();
    this.auth.postForm('order-upload/',[{'file':file.target.files[0]},{'user':this.user.id}]).subscribe((result:any)=>{
      if(result['id']>0){
        this.spinner.hide();
        this._snackBar.open("Data Uploaded Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
      }
    })
  }

  ustatus(){
    this.spinner.show();
    let cusdata = [];
    for(var i=0; i < this.selection.selected.length; i++){
      cusdata.push(this.selection.selected[i]['id']);
    }
    this.auth.postAPI('status-change/', {'status':this.status, 'ids':cusdata}).subscribe(result => {
      alert(result['results']);
      this.spinner.hide();
    });
  }


  ngOnInit() {
    // console.log("Hello");
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value || '')),
    // );
  }

  orderCall(cmp:any=''){
    var dfltr='';
    if(this.dtfilter){dfltr='&date__gte='+this.sdate+'&date__lte='+this.edate}
    if(this.company != undefined){cmp=this.company?.id}
    this.spinner.show();
    this.auth.getAPI('report/?limit=500&status='+this.fstatus+'&company='+cmp+dfltr).subscribe(result => {
      this.orders = result['results'];
      this.dataSource = new MatTableDataSource(this.orders);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner.hide();
      
    });
  }

  customer(){
    this.spinner.show();
    // let cusdata = [{'id[]':'this.id'},{'notified':true}];
    let cusdata = [];
    for(var i=0; i < this.selection.selected.length; i++){
      cusdata.push({'id[]':this.selection.selected[i]['id']});
    }
    cusdata.push({'notified':true});
    this.auth.postJson('notify-customer/', cusdata).subscribe(result => {
      alert(result['results']);
      this.spinner.hide();
    });
  }

  distributor(){
    this.spinner.show();
    let cusdata = [];
    for(var i=0; i < this.selection.selected.length; i++){
      cusdata.push({'id[]':this.selection.selected[i]['id']});
    }
    cusdata.push({'notified':true});
    this.auth.postJson('notify-company/', cusdata).subscribe(result => {
      alert(result['results']);
      this.spinner.hide();
    });
  }

  private _filter(value: any): string[] {
    const filterValue = (value?.account || value)?.toLowerCase();
    // console.log(filterValue, this.company);
    return this.companies.filter(option => option.account?.toLowerCase()?.includes(filterValue));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: OrderData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  SortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}