<main>
	<app-topnav></app-topnav>
	<section class="container maintenance" *ngIf="maintdata.title!=''">
		<div><img src="assets/images/503.png" alt="MyClearAmber"></div>
		<h1>{{maintdata.title}}</h1>
		<h3>{{maintdata.message}}</h3>
	</section>
	<section class="container-fluid" *ngIf="maintdata.title==''">
		<div class="row">
			<div class="col-md-3">
				<app-orderlist (PresentOrder)="sorder = $event;" (Emergency)="maintdata = $event;" [uorder]="norder"></app-orderlist>
			</div>
			<div class="col-md-6">
				<app-chat [order]="sorder"></app-chat>
			</div>
			<div class="col-md-3">
				<app-order-display (UpdateOrder)="norder = $event;" [order]="sorder"></app-order-display>
			</div>
		</div>
	</section>
</main>
