<div class="bulk">
	<h2 mat-dialog-title>BULK EDIT CAD RapidKey Data</h2>
	<section>
		<table mat-table [dataSource]="dataSource" class="full-width-table table-bordered" aria-label="Elements">

			<!-- Name Column -->
	        <ng-container matColumnDef="sale">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>SO Number</th>
	          <td mat-cell *matCellDef="let row">{{row.sale}}</td>
	        </ng-container>

	        <ng-container matColumnDef="despatch">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Despatch</th>
	          <td mat-cell *matCellDef="let row">{{row.despatch|date:'Y-MM-dd'}}</td>
	        </ng-container>
	        
	        <ng-container matColumnDef="customer">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Customer" [(ngModel)]="row.customer"></td>
	        </ng-container>

	        <ng-container matColumnDef="address">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Address" [(ngModel)]="row.address"></td>
	        </ng-container>

	        <ng-container matColumnDef="town">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Town</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Town" [(ngModel)]="row.town"></td>
	        </ng-container>

	        <ng-container matColumnDef="postcode">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Postcode" [(ngModel)]="row.postcode"></td>
	        </ng-container>

	        <ng-container matColumnDef="weight">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Weight</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Weight" [(ngModel)]="row.weight" type="number"></td>
	        </ng-container>

	        <ng-container matColumnDef="parcel">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Parcel</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Parcel" [(ngModel)]="row.parcel" type="number"></td>
	        </ng-container>

	        <ng-container matColumnDef="loaded">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Loaded</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Loaded" [(ngModel)]="row.loaded" type="number"></td>
	        </ng-container>

	        <ng-container matColumnDef="over">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Over 4m</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Over 4m" [(ngModel)]="row.over" type="number"></td>
	        </ng-container>

	        <ng-container matColumnDef="note">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="Note" [(ngModel)]="row.note"></td>
	        </ng-container>

	        <ng-container matColumnDef="vanno">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>VAN No</th>
	          <td mat-cell *matCellDef="let row"><input matInput placeholder="VAN No" [(ngModel)]="row.vanno"></td>
	        </ng-container>

	        <ng-container matColumnDef="area">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Area</th>
	          <td mat-cell *matCellDef="let row">
	            <mat-form-field appearance="fill">
	              <mat-label>Select a Area</mat-label>
	              <mat-select [(value)]="row.area">
	                <mat-option [value]="''">-- SELECT AREA --</mat-option>
	                <mat-option *ngFor="let area of areas" [value]="area.id">{{area.title}}</mat-option>
	              </mat-select>
	            </mat-form-field>
	          </td>
	        </ng-container>

	        <ng-container matColumnDef="scanned">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Scanned</th>
	          <td mat-cell *matCellDef="let row">
	            <mat-form-field appearance="fill">
	              <mat-label>Scanned</mat-label>
	              <mat-select [(value)]="row.scanned">
	                <mat-option [value]="true">YES</mat-option>
	                <mat-option [value]="false">NO</mat-option>
	              </mat-select>
	            </mat-form-field>
	          </td>
	        </ng-container>

	        <ng-container matColumnDef="delivered">
	          <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivered</th>
	          <td mat-cell *matCellDef="let row">
	            <mat-form-field appearance="fill">
	              <mat-label>Delivered</mat-label>
	              <mat-select [(value)]="row.delivered">
	                <mat-option [value]="true">YES</mat-option>
	                <mat-option [value]="false">NO</mat-option>
	              </mat-select>
	            </mat-form-field>
	          </td>
	        </ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
	        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</section>
	<div>
		<mat-dialog-actions align="start">
		  	<button mat-raised-button class="buttonSubmit mr-2" (click)="saveBulk()">Save</button>
		  	<button mat-raised-button mat-dialog-close color="warn">Cancel</button>
		</mat-dialog-actions>
	</div>
</div>