import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../core/services/authentication.service'

@Component({
  selector: 'app-nqs',
  templateUrl: './nqs.component.html',
  styleUrls: ['./nqs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NqsComponent implements OnInit {

	oid:number;
	ntype = {'id':'', 'title':''};
	nreason:number;
	ngroup:any = [];
	ngroups:any = [];
	cost:number;
	replacement:number = 0;
	note:string;
	order:any = [];
	nqstype:any = [];
	nqsreason:any = [];
	nqsgroup:any = [];
	user:any = {'id':0, 'username':''};
	nqsdisable:boolean = false;
	constructor(@Inject(MAT_DIALOG_DATA) public data:any, private auth:AuthenticationService, private dailogRef:MatDialogRef<NqsComponent>, private spinner: NgxSpinnerService, private _snackBar: MatSnackBar){
		this.oid = data['oid'];
		this.order = data['order'];

		this.auth.getAPI('nqstype/').subscribe(result => {
			this.nqstype = result['results'];
		});
		this.auth.getAPI('nqsreason/').subscribe(result => {
			this.nqsreason = result['results'];
		});
		this.auth.getAPI('nqsgroup/').subscribe(result => {
			this.nqsgroup = result['results'];
		});
	}

	ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem('profile'));
	}

	nqsType(){
		if (this.ntype.title == 'CREDIT') {
			this.auth.getAPI('nqs/?order='+this.oid+'&nqs_type='+this.ntype.id).subscribe(result => {
				if (result['count'] > 0) {
					this.nqsdisable = true;
				}else{
					this.nqsdisable = false;
				}
			});
		}else{
			this.nqsdisable = false;
		}
	}

	add(){
		// console.log("Add NQS", this.ngroup.length);
		if(this.ngroup.length == 0){
			this._snackBar.open("Please Select atlease 1 Product Group",'', {duration: 5000, panelClass: ['alertSnackbar']});
			return false;
		}
		this.spinner.show();
		var data:any[] = [{'initial':this.user.id}, {'order':this.oid}, {'nqs_type':this.ntype.id}, {'reason':this.nreason}, {'cost':this.cost}, {'replacement':this.replacement}, {'note':this.note}]
		for(let g of this.ngroup){
			data.push({'group':g});
		}
		this.auth.postForm('nqs/',data).subscribe(result => {
			this._snackBar.open("NQS added successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
			this.dailogRef.close();
			this.spinner.hide();
		},
		error => {
			if(error.error.detail=="Invalid token."){
				alert("You have Logined from Another Device, Please Login Again...!");
				localStorage.clear();
				// this.navCtrl.navigateForward('/login');
				return false;
			}

			if (error.status==400) {
				let msg = "";
				for (let key in error.error) {
					msg = msg+" "+key+": "+error.error[key]+"\n";
				};
				if (!error.error.registration_id) {
					if(msg.indexOf('unique set')>0){
						alert("Duplicate NQS Entry");
					}else{
						alert(msg);
					}
					// alert("<ol>"+msg+"</ol>");
				}
			}

			if (error.status==401) {
				alert("Authentication issue, Please Login again...!");
			}

			if (error.status==0 || error.status==404 || error.status==500) {
				alert("Something went wrong...!");
			}
			this.spinner.hide();

		});
	}

}
