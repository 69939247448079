import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Router, NavigationEnd } from '@angular/router';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, throwError } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { FilterPipe } from '../../../pipes/custom/filter.pipe';
import { FilterArrayPipe } from '../../../pipes/custom/filter-array.pipe';
import { SortByPipe } from '../../../pipes/custom/sort-by.pipe';
import { NoteComponent } from '../../../shared/dialogs/note/note.component';
import { StatusComponent } from '../../../shared/dialogs/status/status.component';
import { BulkChatComponent } from '../../../shared/dialogs/bulk-chat/bulk-chat.component';

@Component({
  selector: 'app-clist',
  templateUrl: './clist.component.html',
  styleUrls: ['./clist.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ClistComponent implements OnInit {
  @Output() public PresentOrder = new EventEmitter();
  @Output() public Emergency = new EventEmitter();
  @Input()  public uorder: number;

  orders:any = [];
  allOrders:any = [];
  distributors:any = [];
  groups:any = [];
  tags:any = [];
  order:any;
  currentIndex:number = 0;
  current:any;
  search:string;
  status:string;
  group:string;
  company:any = [];
  selectOrders:any = [];
  tag:string;
  unread:boolean;
  read:boolean;
  remaining = 0;
  limit = 200;
  maxchat = 6000;
  rurl:string='chatorder2';
  url:string='chatorderes2';
  next:any = 'limit='+this.limit+'&offset=0';
  // next:any;
  user:any;
  groupForm = new UntypedFormControl();
  distForm = new UntypedFormControl();
  tagForm = new UntypedFormControl();
  statusForm = new UntypedFormControl();

  visible = true;
  selectable = true;
  removable = true;
  allComplete = false;
  cad:boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new UntypedFormControl();
  filteredCompanies: Observable<string[]>;
  companies: string[] = [];
  allCompanies: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private auth:AuthenticationService, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar, private filterPipe:FilterPipe, private filterArray:FilterArrayPipe, private sortBy:SortByPipe, private spinner: NgxSpinnerService){
    this.spinner.show();
    if(this.router.url == '/cad'){
      this.cad = false;
      this.url = 'cadorder';
    }
    this.chatOrders();
    this.distLoad();
    // this.groupLoad();
    this.tagLoad();

    // setInterval(() => { this.refresh(); }, 8*1000);
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('profile'));
    this.status = JSON.parse(localStorage.getItem('status'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.id = changes.uorder.currentValue.id;
    // this.singleOrder(this.id);
    // console.log("Updated Order ", changes.uorder.currentValue);
    if (this.uorder != undefined) {
      // this.orders[this.currentIndex] = this.uorder;
      // let indexx = this.orders.findIndex(x => x.id === this.uorder.id);
      let indexx = this.orders.findIndex(x => x.id === changes.uorder.currentValue.id);
      this.orders[indexx] = this.uorder;
      this.current = this.uorder;
    }
  }

  refresh(){
    this.auth.getAPI(this.rurl+'/?limit=50').subscribe(result => {
        var ords = this.allOrders.reverse();
        // this.orders = [].concat(this.orders, result['results']);
        this.removeold(result['results']);
        this.allOrders = [].concat(this.allOrders, result['results'].reverse());
        // this.allOrders = this.removeDuplicates(this.allOrders, "sale");
        this.orders = this.allOrders.reverse();

        setTimeout(() => { this.refresh(); }, 5*1000);
      });
  }

  removeold(result){
      // console.log(result);
      for(let i=0; i<result.length; i++){
        const pos = this.allOrders.map(e => e.sale).indexOf(result[i]['sale']);
        this.allOrders.splice(pos, 1);
        // console.log(result[i], pos);
      }
  }

  // removeDuplicates(myArray, Prop) {
  //   return myArray.filter((obj, pos, arr) => {
  //     return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
  //   });
  //   // return myArray.filter((item, index) => {
  //   //     return myArray.indexOf(item.sale) == index.sale;
  //   // });
  // }

  changeStatus(){
    const dialogRef = this.dialog.open(StatusComponent,{
      data:{
        tags:this.tags,
        orders:this.selectOrders,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.allComplete = true;
        this.auth.postAPI('tag-change/',{'ids':this.selectOrders,'tag':result}).subscribe(data => {
          this.selectOrders = [];
          this.allComplete = false;
        });
      }
    });
  }

  bulkMsg(){
    if(this.selectOrders.length == 0){
      this._snackBar.open("Please Select orders to send Bulk Message", '', {duration: 3000, panelClass: ['redPAddSnackbar']});
      return false;
    }
    const dialogRef = this.dialog.open(BulkChatComponent,{
      data:{
        // tags:this.tags,
        orders:this.selectOrders,
      }
    });
  }

  setOrder(e,oid){
    if(e){
      this.selectOrders.push(oid);
    }else{
      this.selectOrders.pop(oid);
    }
  }

  chatOrders(){
    this.auth.getAPI(this.url+'/?'+this.next)
      .pipe(catchError(error => {
        if (error.status == 503) {
          this.Emergency.emit(error.error.error);
          this.spinner.hide();
        }
        return throwError(error);
      }))
      .subscribe(result => {
        this.orders = [].concat(this.orders, result['results']);
        this.allOrders = [].concat(this.allOrders, result['results']);
        if (result['next']!=null) {
          this.next = result['next'].split('?')[1];
          this.remaining = result['count']-parseInt(this.next.split('=')[2]);
        }
        if (this.remaining < 0) {
          this.remaining = 0;
        }
        if(parseInt(this.next.split('=')[2]) == this.limit || this.current == undefined){
          // this.current = this.orders[0];
          // this.PresentOrder.emit(this.orders[0]);
        }
        if(this.allOrders.length != result['count'] && this.allOrders.length < this.maxchat){
          // this.chatOrders();   // Temp comment for Speed improvment
        }else{
          this.remaining = 0;
          this.distLoad(true);
          this.tagLoad(true);
        }
        this.spinner.hide();
        setTimeout(() => { this.refresh(); }, 10*1000);
      });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our comp
    if ((value || '').trim()) {
      // this.companies.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(comp: string): void {
    const index = this.companies.indexOf(comp);

    if (index >= 0) {
      this.companies.splice(index, 1);
      this.company.splice(index, 1);
    }
    this.orders = this.filterArray.transform(this.allOrders,this.company, 'company');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.company.push(event.option.value.id);
    this.orders = this.filterArray.transform(this.allOrders,this.company, 'company');
    this.companies.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.PresentOrder.emit({'id':0});
  }

  private _filter(value: any): any[] {
    var filterValue = value;
    if (typeof(value) == 'object') {
      filterValue = value['account'].toLowerCase();
    }else{
      filterValue = value.toLowerCase();
    }

    return this.allCompanies.filter(comp => comp['account'].toLowerCase().indexOf(filterValue) === 0);
  }

  readCount(){
    this.read = !this.read;
  }

  unreadCount(){
    this.unread = !this.unread;
  }

  singleOrder(id){
    // this.PresentOrder.emit(id);
    // this.auth.getAPI('order/'+id+'/').subscribe(result => {
    //  this.order = result;
    //  console.log(this.order);
    // });
  }

  orderCall(obj, i){
    this.currentIndex = i;
    this.current = obj;
    this.PresentOrder.emit(obj);
  }

  distLoad(reload:boolean = false){
    if(localStorage.getItem('companylist') == null || reload){
      this.auth.getAPI('distributors/?limit=500').subscribe(result => {
        this.distributors = result['results'];
        localStorage.setItem('companylist', JSON.stringify(result['results']) );
        this.allCompanies = result['results'];
        this.filteredCompanies = this.fruitCtrl.valueChanges.pipe(startWith(null), map((comp: any | null) => comp ? this._filter(comp) : this.allCompanies.slice()));
      });
    }else{
      this.distributors = JSON.parse(localStorage.getItem('companylist'));
      this.allCompanies = JSON.parse(localStorage.getItem('companylist'));
      this.filteredCompanies = this.fruitCtrl.valueChanges.pipe(startWith(null), map((comp: any | null) => comp ? this._filter(comp) : this.allCompanies.slice()));
    }
  }

  tagLoad(reload:boolean = false){
    if(localStorage.getItem('taglist') == null || reload){
      this.auth.getAPI('tag/').subscribe(result => {
        this.tags = result['results'];
        this.tag = JSON.parse(localStorage.getItem('tag'));
        localStorage.setItem('taglist', JSON.stringify(result['results']) );
      });
    }else{
      this.tags = JSON.parse(localStorage.getItem('taglist'));
      this.tag = JSON.parse(localStorage.getItem('tag'));
    }
  }

  more(param){
    this.auth.getAPI(this.url+'/?'+param).subscribe(result => {
      this.orders = [].concat(this.orders, result['results']);
      this.allOrders = [].concat(this.allOrders, result['results']);
      if (result['next'] != null) {
        this.next = result['next'].split('?')[1];
      }
      this.remaining -= this.limit;
      if (this.remaining < 0) {
        this.remaining = 0;
      }
    });
  }

  statusFilter(){
    if (this.status.length == 0) {
      localStorage.removeItem('status');
    }else{
      this.PresentOrder.emit({'id':0});
      localStorage.setItem('status', JSON.stringify(this.status) );
    }
  }

  groupFilter(){
    if (this.group.length == 0) {
      localStorage.removeItem('group');
    }else{
      this.PresentOrder.emit({'id':0});
      localStorage.setItem('group', JSON.stringify(this.group) );
    }
  }

  companyFilter(){
    if (this.company.length == 0) {
      localStorage.removeItem('company');
    }else{
      this.PresentOrder.emit({'id':0});
      localStorage.setItem('company', JSON.stringify(this.company) );
    }
  }

  tagFilter(){
    if (this.tag.length == 0) {
      localStorage.removeItem('tag');
    }else{
      this.PresentOrder.emit({'id':0});
      localStorage.setItem('tag', JSON.stringify(this.tag) );
    }
  }

  note(ordr){
    const dialogRef = this.dialog.open(NoteComponent,{
      data:{
        oid:ordr.id,
        order:ordr,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      let indexx = this.orders.findIndex(x => x.id === result.id);
      this.orders[indexx] = result;
    });
  }

}
