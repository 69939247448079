import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
	// domain = '127.0.0.1:8000';
	domain = 'myclearamber.com';
	// domain = 'mcatest.grokurv.co.uk';
	// baseUrl: string = 'http://'+this.domain;
	baseUrl: string = 'https://myclearamber.com';
	// baseUrl: string = 'https://mcatest.grokurv.co.uk';
	apiUrl: string = this.baseUrl+'/mycapi/v1/';
	// socketUrl: string = 'ws://'+this.domain;
	socketUrl: string = 'wss://'+this.domain;
	httpOptions: any;

	constructor(private http: HttpClient, private router:Router){}

	getAPI(url): Observable<any> {
		this.httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		if (localStorage.getItem("token")) {
			this.httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'token '+localStorage.getItem("token") })
			};
		}
		var response = this.http.get(this.apiUrl+url, this.httpOptions)
			.pipe(map(data => {
					// this.spinner.hide();
					return data;
				})
			)
			.pipe(catchError(error => {
					// this.spinner.hide();
					if (error.status == 401) {
						localStorage.clear();
						// this.router.navigate(['login']);
						window.location.href = this.baseUrl;
					}
					return throwError(error);
				})
			)
		return response;
	}

	postAPI(url, params): Observable<any> {
		this.httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		if (localStorage.getItem("token")) {
			this.httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'token '+localStorage.getItem("token") })
			};
		}
		return this.http.post(this.apiUrl+url, params, this.httpOptions);
	}

	postJson(url, params) {
		this.httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		if (localStorage.getItem("token")) {
			this.httpOptions = {
				headers: new HttpHeaders({'Authorization': 'token '+localStorage.getItem("token") })
			};
		}
		var formData: any = new FormData();
		for (var i = 0; i < params.length; ++i) {
			var key = Object.keys(params[i])[0];
			var prm = params[i][0];
			formData.append(key, JSON.stringify(params[i][key]) );
		}
		// formData.append("id", JSON.stringify(array));
		return this.http.post(this.apiUrl+url, formData, this.httpOptions);
	}

	postForm(url, params) {
		this.httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		if (localStorage.getItem("token")) {
			this.httpOptions = {
				headers: new HttpHeaders({'Authorization': 'token '+localStorage.getItem("token") })
			};
		}
		var formData: any = new FormData();
		for (var i = 0; i < params.length; ++i) {
			var key = Object.keys(params[i])[0];
			var prm = params[i][0];
			formData.append(key, params[i][key] );
		}
		return this.http.post(this.apiUrl+url, formData, this.httpOptions);
	}

	putAPI(url, params): Observable<any> {
		this.httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		if (localStorage.getItem("token")) {
			this.httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'token '+localStorage.getItem("token") })
			};
		}
		return this.http.put(this.apiUrl+url, params, this.httpOptions);
	}

	patchAPI(url, params): Observable<any> {
		this.httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		if (localStorage.getItem("token")) {
			this.httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'token '+localStorage.getItem("token") })
			};
		}
		return this.http.patch(this.apiUrl+url, params, this.httpOptions);
	}

	deleteAPI(url): Observable<any> {
		this.httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		if (localStorage.getItem("token")) {
			this.httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'token '+localStorage.getItem("token") })
			};
		}
		return this.http.delete(this.apiUrl+url, this.httpOptions);
	}
}
