import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusComponent implements OnInit {
	status:string;
	tags:any = [];
	tag:number;
	constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dailogRef:MatDialogRef<StatusComponent>){
		this.tags = data['tags'];
	}

	ngOnInit(): void {
	}

	statusNew(){
		console.log(this.tag);
	}

	change(){
		this.dailogRef.close(this.tag);
	}

	cancel(){
		this.dailogRef.close();
	}

}
