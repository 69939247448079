<div class="status">
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

	<div class="row">
		<!-- <div class="col-md-12">
			<img src="assets/images/nqs.png" alt="NQS" class="img-fluid">
		</div> -->
		<div class="col-md-12 heading">
			<h2>Change Colour Code for selected Orders</h2>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12">
			<mat-form-field appearance="outline" class="w100">
				<mat-label>Select Colour Code</mat-label>
				<mat-select [(value)]="tag" (selectionChange)="statusNew()">
					<mat-option *ngFor="let tag of tags" [value]="tag.id">{{tag.title}}</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- <mat-form-field appearance="outline" class="w100">
				<mat-label>Select Status</mat-label>
				<mat-select [(value)]="status" (selectionChange)="statusNew()">
					<mat-option value="Received">Received</mat-option>
					<mat-option value="Production">Production</mat-option>
					<mat-option value="Despatch">Despatch</mat-option>
					<mat-option value="Distribution">Distribution</mat-option>
					<mat-option value="On Its Way">On Its Way</mat-option>
					<mat-option value="Delivered">Delivered</mat-option>
				</mat-select>
			</mat-form-field> -->
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-6"><button mat-raised-button (click)="change()">CHANGE COLOUR CODE</button></div>
		<div class="col-md-2"></div>
		<div class="col-md-4"><button mat-raised-button (click)="cancel()">CANCEL</button></div>
	</div>
</div>