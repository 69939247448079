import { Component, OnInit, AfterViewInit, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import * as Highcharts from 'highcharts';

import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ResponseComponent implements OnInit {
	@Input()  public dateChange: any;

	profile:any;
	maintenance:boolean = false;
	maintdata:any = {'title':'','message':''};
	averageOriginal = {'colours':[], 'series':[]};
	averageData = {'colours':[], 'series':[]};
	// colorList:any;

	weekAvg:any = {
	  chart: {
		type: 'areaspline'
	  },
	  title: {
		text: 'Average Response Time Weekly'
	  },
	  subtitle : {
		 style: {
			position: 'absolute',
			right: '0px',
			bottom: '10px'
		 }
	  },
	  legend : {
		 layout: 'vertical',
		 align: 'left',
		 verticalAlign: 'top',
		 x: -250,
		 y: 100,
		 floating: true,
		 borderWidth: 1,
		
		 backgroundColor:'#fdd7af'
	  },
	  xAxis:{
		 categories: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
		 plotBands: [{ // visualize the weekend
			from: 4.5,
			to: 6.5,
			color: '#fcd2a2'
		 }]
	  },
	  yAxis : {
		 title: {
			text: 'Average Response Time(minutes)'
		 }
	  },
	  tooltip : {
		 shared: true, valueSuffix: ' Minutes'
	  },
	  plotOptions : {
		 area: {
			fillOpacity: 0.5 
		 }
	  },
	  credits:{
		enabled: false
	  },
	  // colors: this.averageData['colours'],
	  colors: ['#f78e1d', '#50B432', '#ED561B', '#DDDF00'],
	  series: this.averageData['series']
	}

	hourAvg:any = {   
		 chart: {
		   type: 'line'
		 },
		 title: {
		   text: 'Average Response Time Hourly'
		 },
		 legend : {
			layout: 'vertical',
			align: 'left',
			verticalAlign: 'top',
			x: -250,
			y: 100,
			floating: true,
			borderWidth: 1,
			backgroundColor:'#fdd7af'
		 },
		 xAxis:{
			categories: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24']
		 },
		 yAxis : {
			title: {
			   text: 'Average Response Time(minutes)'
			}
		 },
		 tooltip : {
			shared: true, valueSuffix: ' Minutes'
		 },
		 plotOptions : {
			// area: {fillOpacity: 0.5},
			dataLabels: {enabled: true}
		 },
		 credits:{
		   enabled: false
		 },
		 // colors: this.averageData['colours'],
		 colors: ['#f78e1d', '#50B432', '#ED561B', '#DDDF00'],
		 series: this.averageData['daily']
	};
	constructor(private router:Router, private auth:AuthenticationService, private spinner: NgxSpinnerService){
		this.profile = JSON.parse(localStorage.getItem('profile'));
		if(this.profile.role!="Employee"){
			this.router.navigate(['']);
		}
	}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		// this.id = changes.order.currentValue.id;
		// this.singleOrder(this.id);
		if(changes.dateChange.currentValue != undefined){
			console.log(changes.dateChange.currentValue);
			this.auth.getAPI('responsetime/'+changes.dateChange.currentValue+'/')
				.pipe(catchError(error => {
				   if (error.status == 503) {
					  this.maintenance = true;
					  this.maintdata = error.error.error;
					  this.spinner.hide();
				   }
				   return throwError(error);
				}))
				.subscribe(result => {
					this.averageOriginal = result.results;
					this.averageData = result.results;
					// this.colorList = result['series'];
					this.updateChart();
					this.spinner.hide();
				});
		}
	}

	ngAfterViewInit(){
		this.spinner.show();
		this.auth.getAPI('responsetime/')
			.pipe(catchError(error => {
			   if (error.status == 503) {
				  this.maintenance = true;
				  this.maintdata = error.error.error;
				  this.spinner.hide();
			   }
			   return throwError(error);
			}))
			.subscribe(result => {
				this.averageOriginal = result.results;
				this.averageData = result.results;
				// this.colorList = result['series'];
				this.updateChart();
				this.spinner.hide();
			});
	}

	updateChart(){
		// this.weekAvg.colors = this.averageData['colours'];
		this.weekAvg.series = this.averageData['weekly'];
		// this.weekAvg.series = {name:"", data:this.averageData['weekly']};
		// console.log(this.averageData['weekly'], this.weekAvg.series);
		Highcharts.chart('weekAvg',this.weekAvg);

		// this.hourAvg.colors = this.averageData['colours'];
		this.hourAvg.series = this.averageData['hoyurly'];
		Highcharts.chart('hourAvg',this.hourAvg);
	}

}
