<div class="nqs">
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

	<div class="row">
		<div class="col-md-4">
			<img src="assets/images/nqs.png" alt="NQS" class="img-fluid">
		</div>
		<div class="col-md-8 heading">
			<h2 style="color: #fff;font-size: 32px;">FOLLOW UP NOTE</h2>
			<div>DIST CODE: {{order.companies.account}}</div>
			<div>ORDER NO: {{order.sale}}</div>
			<div>DIST REF: {{order.reference}}</div>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12">
			<!-- <mat-form-field appearance="fill" class="w100">
				<mat-label>DATE :</mat-label>
				<input [(ngModel)]="date" matInput placeholder="Date" value="">
			</mat-form-field> -->
			<mat-form-field appearance="fill" class="w100">
			  <mat-label>DATE :</mat-label>
			  <input [value]="date.value" (dateChange)="dateChange($event)" matInput [matDatepicker]="picker">
			  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			  <mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Initials :</mat-label>
				<input [(ngModel)]="initial" matInput placeholder="Initials" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Internal Note</mat-label>
				<textarea [(ngModel)]="note" matInput placeholder="Note..." rows="3"></textarea>
			</mat-form-field>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-6"><button mat-raised-button (click)="add()">ADD Note</button></div>
		<div class="col-md-3"><button mat-raised-button color="warn" (click)="delete()">DELETE</button></div>
		<div class="col-md-3"><button mat-raised-button (click)="cancel()">CANCEL</button></div>
	</div>
</div>