import { Component, OnInit, Input, ViewEncapsulation, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class PieComponent implements OnInit {
	@ViewChild('chart') public chartElement: ElementRef;
	@Input() graph:any;
	@Input() who:string;

	Highcharts: typeof Highcharts = Highcharts; // required
	chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
	chartOptions: Highcharts.Options = {
		title: {
			text: ''
		},
		plotOptions: {
			pie: {
				shadow: false,
				borderWidth: 0,
				borderColor: 'black'
			}
		},
		series: [{
			data: [8, 1],
			type: 'pie',
			innerSize: '50%',
			showInLegend:false,
			dataLabels: {
				enabled: false
			}
		}],
		colors: ['#f78e1e', '#58595B']
	}; // required
	chartRef: Highcharts.Chart;
	chartCallback: Highcharts.ChartCallbackFunction = function (chart) {
		this.chartRef = chart;
		// console.log(chart);
	}; // optional function, defaults to null
	updateFlag: boolean = true; // optional boolean
	oneToOneFlag: boolean = true; // optional boolean, defaults to false
	runOutsideAngular: boolean = false; // optional boolean, defaults to false

	mygf = 'container';
	constructor(){}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		// console.log(this.graph, this.who, changes);
		if (this.graph.length > 0) {
			this.chartOptions.series[0]['data'] = this.graph;
			if (this.who == 'received') { Highcharts.chart('received', this.chartOptions); }
			if (this.who == 'production') { Highcharts.chart('production', this.chartOptions); }
			if (this.who == 'despatch') { Highcharts.chart('despatch', this.chartOptions); }
			if (this.who == 'distribution') { Highcharts.chart('distribution', this.chartOptions); }
			if (this.who == 'onway') { Highcharts.chart('onway', this.chartOptions); }
			if (this.who == 'delivered') { Highcharts.chart('delivered', this.chartOptions); }
		}
	}

}
