import { Component, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';
import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-cadrkdash',
  templateUrl: './cadrkdash.component.html',
  styleUrls: ['./cadrkdash.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CadrkdashComponent implements AfterViewInit {
  maintenance:boolean = false;
  vans:any=[];
  areas:any=[];
  area:any={'title':'','id':0};
  total:number=0;
  scanned:number=0;
  scannedp:number=0;
  delivered:number=0;
  deliveredp:number=0;
  today:any;
  date:any = new Date();
  rkcpr:any = '';

  cprChart:any = {   
    chart: {
      type: 'column',
      backgroundColor: '#58595b',
      height: 300,
    },
    title: {
      text: 'CPR THIS MONTH',
      style: {
        color:'#ffffff',
        fontSize: '26px !important',
      }
    },
    legend : {
       layout: 'vertical',
       align: 'left',
       verticalAlign: 'top',
       x: -150,
       y: 500,
       floating: true,
       borderWidth: 1,
      
       backgroundColor:'#58595b'
    },
    xAxis:{
       categories: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30'],
       labels: {
            style: {
                color: 'white'
            }
        }
    },
    yAxis : {
      title: {
          text: ''
      },
      labels: {
            style: {
                color: 'white'
            }
      },
      max:40,
      plotLines: [{
          value: 15,
          color: 'red',
          width: 3,
          label: {
              text: 'Theoretical mean: 932',
              align: 'right',
              style: {color: 'white'}
          }
      }]
    },
    tooltip : {
       shared: true, valueSuffix: ' '
    },
    plotOptions : {
       area: {
          fillOpacity: 0.5 
       }
    },
    credits:{
      enabled: false
    },
    colors: ['#f78e1d', '#50B432', '#ED561B', '#DDDF00'],
    series: [
       {
          name: 'CPR',
          colorByPoint: true,
          data: [0, 0, 0, 0, 0, 0, 20, 0, 0, 0, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor:'#fdd7af'
       }
    ]
  };

  constructor(private auth:AuthenticationService, private spinner: NgxSpinnerService){
    if(localStorage.getItem('rkdate') != null){
      this.date = new Date(localStorage.getItem('rkdate'));
    }
    this.today = formatDate(this.date, 'yyyy-MM-dd', 'en');
  }
  ngAfterViewInit() {
    this.spinner.show();
    this.auth.getAPI('rkarea/').subscribe(result => {
      this.areas = result['results'];
    });
    this.auth.getAPI('rkvandata/?limit=200&date='+this.today).subscribe(result => {
      this.vans = result['results'];
      this.datacal();
      this.spinner.hide();
    });
    this.cprdata();
  }

  dchange(event: any){
    this.spinner.show();
    localStorage.setItem('rkdate', event.value);
    this.today = formatDate(event.value, 'yyyy-MM-dd', 'en');
    var ara = this.area?.id;
    if(ara == undefined){ara=''}
    this.auth.getAPI('rkvandata/?limit=200&date='+this.today+'&area='+ara).subscribe(result => {
      this.vans = result['results'];
      this.datacal();
      this.spinner.hide();
    });
    this.cprdata();
  }

  areaFilter(){
    this.spinner.show();
    var ara = this.area?.id;
    if(ara == undefined){ara=''}
    this.auth.getAPI('rkvandata/?limit=200&date='+this.today+'&area='+ara).subscribe(result => {
      this.vans = result['results'];
      this.datacal();
      this.spinner.hide();
    });
    this.cprdata();
  }

  datacal(){
    this.total = 0;
    this.scanned = 0;
    this.scannedp = 0;
    this.delivered = 0;
    this.deliveredp = 0;
    for(let i=0; i < this.vans.length; i++){
      for(let j=0; j < this.vans[i]['rkdata'].length; j++){
        this.total++;
        if(this.vans[i]['rkdata'][j]['scanned']){ this.scanned++; }
        if(this.vans[i]['rkdata'][j]['delivered']){ this.delivered++; }
      }
    }
    if(this.total > 0){
      this.scannedp = Math.round((this.scanned/this.total)*100);
      this.deliveredp = Math.round((this.delivered/this.total)*100);
    }
  }

  cprdata(){
    var ara = this.area?.id;
    if(ara == undefined){ara = '';}
    this.auth.getAPI('rkcpr/?date='+this.today+'&area='+ara).subscribe(result => {
      this.rkcpr = result['results'];
      this.cprChart.series[0].data = this.rkcpr['graph']['data'];
      this.cprChart.yAxis.plotLines = this.rkcpr['lines'];
      this.cprChart.colors = this.rkcpr['graph']['colours'];
      this.cprChart.xAxis.categories = this.rkcpr['graph']['months'];
      Highcharts.chart('cprChart',this.cprChart);
    });
  }

}
