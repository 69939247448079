import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './custom/filter.pipe';
import { FilterArrayPipe } from './custom/filter-array.pipe';
import { DisplayLinkPipe } from './custom/display-link.pipe';
import { SortByPipe } from './custom/sort-by.pipe';

@NgModule({
  declarations: [FilterPipe, FilterArrayPipe, DisplayLinkPipe, SortByPipe],
  imports: [
    CommonModule
  ],
  exports: [FilterPipe, FilterArrayPipe, DisplayLinkPipe, SortByPipe ],
  providers: [ FilterPipe, FilterArrayPipe, DisplayLinkPipe, SortByPipe ]
})
export class PipesModule { }
