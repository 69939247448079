import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { HighchartsChartModule } from 'highcharts-angular';

import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { TopnavComponent } from './navbar/topnav/topnav.component';
import { OrderlistComponent } from './common/orderlist/orderlist.component';
import { OrderDisplayComponent } from './common/order-display/order-display.component';
import { ChatComponent } from './common/chat/chat.component';
import { NqsComponent } from './dialogs/nqs/nqs.component';
import { PipesModule } from '../pipes/pipes.module';
import { PieComponent } from './graph/pie/pie.component';
import { ImageComponent } from './dialogs/image/image.component';
import { PhraseComponent } from './dialogs/phrase/phrase.component';
import { NoteComponent } from './dialogs/note/note.component';
import { StatusComponent } from './dialogs/status/status.component';
import { BulkChatComponent } from './dialogs/bulk-chat/bulk-chat.component';
import { CadFormComponent } from './dialogs/cad-form/cad-form.component';
import { DeleteComponent } from './dialogs/delete/delete.component';
import { CadBulkEditComponent } from './dialogs/cad-bulk-edit/cad-bulk-edit.component';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { AlertComponent } from './dialogs/alert/alert.component';
import { ClistComponent } from './chats/clist/clist.component';
import { CchatComponent } from './chats/cchat/cchat.component';
import { CorderComponent } from './chats/corder/corder.component';



@NgModule({
  declarations: [TopnavComponent, OrderlistComponent, OrderDisplayComponent, ChatComponent, NqsComponent, PieComponent, ImageComponent, PhraseComponent, NoteComponent, StatusComponent, BulkChatComponent, CadFormComponent, DeleteComponent, CadBulkEditComponent, ConfirmComponent, AlertComponent, ClistComponent, CchatComponent, CorderComponent],
  exports: [TopnavComponent, OrderlistComponent, OrderDisplayComponent, ChatComponent, NqsComponent, PieComponent, ImageComponent, PhraseComponent, ClistComponent, CchatComponent, CorderComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxSpinnerModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    MatCardModule,
    MatListModule,
    MatDividerModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDatepickerModule,
    PipesModule
  ],
})
export class SharedModule { }
