import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { formatDate } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { AuthenticationService } from '../../core/services/authentication.service';

export interface OrderData {
	id: string;
	companies: any;
	reference: string;
	sale: string;
	postcode: string;
	name: string;
	email: string;
	mobile: string;
	date: string;
	status: string;
	// comments: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class DashboardComponent implements AfterViewInit {

	displayedColumns: string[] = ['companies', 'reference', 'sale', 'postcode', 'name', 'email', 'mobile', 'date', 'status'];
	dataSource: MatTableDataSource<OrderData>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	orders:any=[];
	allOrders:any=[];
	// limit:number = 500;
	limit:number = 2000;
	// next:any;
	next:any = 'limit='+this.limit+'&offset=0';
	status:string;
	skey:string;
	profile:any;
	maintenance:boolean = false;
	maintdata:any = {'title':'','message':''}
	graph:any = {'received':[], 'production':[], 'despatch':[], 'distribution':[], 'onway':[], 'delivered':[]};

	range = new UntypedFormGroup({
		start: new UntypedFormControl(),
		end: new UntypedFormControl()
	});

	constructor(private router:Router, private auth:AuthenticationService, private spinner: NgxSpinnerService){
		// this.spinner.show();
		// setTimeout(() => {
		// 	this.spinner.hide();
		// }, 3000);
		this.profile = JSON.parse(localStorage.getItem('profile'));
		// if(this.profile.role != "Employee"){
		// 	this.next += "&company_id="+this.profile.company;
		// }
		// if(this.profile==null){
		// 	window.location.href = this.auth.baseUrl;
		// }
		// console.log(this.profile);
		// console.log(this.profile, this.profile.cadrk_edit_van_only);
		if(!this.profile.dashboard && (this.profile.cadrk_edit_van_only || this.profile.cadrk_edit_only || this.profile.cadrk_view_only) ){
			this.spinner.hide();
			this.router.navigate(['/cadrkdata']);
		}
	}

	// ngOnInit(): void {}

	ngAfterViewInit() {
		this.auth.getAPI('dashboard/').pipe(catchError(error => {
					if (error.status == 503) {
						this.maintenance = true;
						this.maintdata = error.error.error;
					}
					return throwError(error);
				})
			).subscribe(result => {
			this.graph = result['results'];
			// this.spinner.hide();
			// console.log(this.graph);
		});

		this.spinner.show();
		this.orderList();
		// this.dataSource.paginator = this.paginator;
		// this.dataSource.sort = this.sort;
	}

	orderList(){
		this.auth.getAPI('orderes/?'+this.next).subscribe(result => {
			// this.orders = result['results'];
			// this.allOrders = result['results'];
			this.orders = [].concat(this.orders, result['results']);
			this.allOrders = [].concat(this.allOrders, result['results']);
			if (result['next']!=null) {
				this.next = result['next'].split('?')[1];
			}else{
				this.next = null;
			}
			this.dataSource = new MatTableDataSource(this.orders);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.spinner.hide();
			// console.log(this.next, this.next.indexOf(this.limit*10), this.allOrders.length);
			if(this.next != null && this.next.indexOf(this.limit*2) < 0){
				this.orderList();
			}
		});
	}

	// applyFilter(value: any | null){
	// 	this.dataSource.filter = value.trim().toLocaleLowerCase();
 //  }

	applyFilter(filterValue: any | null) {
		// const filterValue = (event.target as HTMLInputElement).value;
		// this.dataSource.filter = filterValue.trim().toLowerCase();
		// if (this.dataSource.paginator) {
		//   this.dataSource.paginator.firstPage();
		// }
		if(filterValue.length > 2){
			this.spinner.show();
			// this.auth.getAPI('orderes/?search='+filterValue+'&limit='+this.limit).subscribe(result => {
			this.auth.getAPI('order/?search='+encodeURIComponent(filterValue)+'&limit='+this.limit).subscribe(result => {
				if(filterValue.length > 2){
					this.orders = result['results'];
					if (result['next']!=null) {
						this.next = result['next'].split('?')[1];
					}
					this.dataSource = new MatTableDataSource(this.orders);
					this.dataSource.paginator = this.paginator;
					this.dataSource.sort = this.sort;
				}
				this.spinner.hide();
			});
			this.spinner.hide();
		}else{
			this.dataSource = new MatTableDataSource(this.allOrders);
			this.spinner.hide();
		}
	}

	fsearch(){
		this.applyFilter(this.skey);
	}

	// applyFilter2(event: Event) {
	// 	const filterValue = (event.target as HTMLInputElement).value;
	// 	// this.dataSource.filter = filterValue.trim().toLowerCase();
	// 	// if (this.dataSource.paginator) {
	// 	//   this.dataSource.paginator.firstPage();
	// 	// }
	// 	if(filterValue.length > 2){
	// 		this.spinner.show();
	// 		this.auth.getAPI('order/?search='+filterValue+'&limit='+this.limit).subscribe(result => {
	// 			this.orders = result['results'];
	// 			if (result['next']!=null) {
	// 				this.next = result['next'].split('?')[1];
	// 			}
	// 			this.dataSource = new MatTableDataSource(this.orders);
	// 			this.dataSource.paginator = this.paginator;
	// 			this.dataSource.sort = this.sort;
	// 			this.spinner.hide();
	// 		});
	// 	}else{
	// 		this.dataSource = new MatTableDataSource(this.allOrders);
	// 	}
	// }

	dateChange(event: any) {
		if (this.range.value.end != null) {
			this.spinner.show();
			var start = formatDate(this.range.value.start, 'yyyy-MM-dd', 'en');
			var end = formatDate(this.range.value.end, 'yyyy-MM-dd', 'en');
			this.auth.getAPI('order/?limit='+this.limit+'&date__gte='+start+'&date__lte='+end).subscribe(result => {
				this.orders = result['results'];
				if (result['next']!=null) {
					this.next = result['next'].split('?')[1];
				}
				this.dataSource = new MatTableDataSource(this.orders);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
				this.spinner.hide();
			});
		}
	}

	filterStatus(sts){
		if (this.status == sts) {
			this.status = '';
		}else{
			this.status = sts;
		}
		this.dataSource.filter = this.status.trim().toLowerCase();	}

}
