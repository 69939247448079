import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../core/services/authentication.service'

@Component({
  selector: 'app-phrase',
  templateUrl: './phrase.component.html',
  styleUrls: ['./phrase.component.scss']
})
export class PhraseComponent implements OnInit {

	title:string;
	content:string;

	constructor(@Inject(MAT_DIALOG_DATA) public data:any, private auth:AuthenticationService, private dailogRef:MatDialogRef<PhraseComponent>, private spinner: NgxSpinnerService, private _snackBar: MatSnackBar) { }

	ngOnInit(): void {
	}

	save(){
		console.log("Add New Phrase");
		console.log(this.title, this.content);
		this.spinner.show();
		this.auth.postAPI('phrase/',{'title':this.title, 'content':this.content}).subscribe(result => {
			this._snackBar.open("PHRASE added successfully",'', {duration: 5000, panelClass: ['blue-snackbar']});
			this.dailogRef.close();
			this.spinner.hide();
		},
		error => {
			if(error.error.detail=="Invalid token."){
				alert("You have Logined from Another Device, Please Login Again...!");
				localStorage.clear();
				// this.navCtrl.navigateForward('/login');
				return false;
			}

			if (error.status==400) {
				let msg = "";
				for (let key in error.error) {
					msg = msg+" "+key+": "+error.error[key]+"\n";
				};
				if (!error.error.registration_id) {
					alert(msg);
					// alert("<ol>"+msg+"</ol>");
				}
			}

			if (error.status==401) {
				alert("Authentication issue, Please Login again...!");
			}

			if (error.status==0 || error.status==404 || error.status==500) {
				alert("Something went wrong...!");
			}

		});
	}

}
