import { Component, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-livechat',
  templateUrl: './livechat.component.html',
  styleUrls: ['./livechat.component.scss']
})
export class LivechatComponent {
  scount = 0;
  sorder:any;
  norder:any;
  maintenance:boolean = false;
  maintdata:any = {'title':'','message':''}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.sorder);
  }

  status(event){
    this.sorder = event;
    if(this.sorder?.status == 'Received'){ this.scount = 1; }
    if(this.sorder?.status == 'Production'){ this.scount = 2; }
    if(this.sorder?.status == 'Despatch'){ this.scount = 3; }
    if(this.sorder?.status == 'Distribution'){ this.scount = 4; }
    if(this.sorder?.status == 'On Its Way'){ this.scount = 5; }
    if(this.sorder?.status == 'Delivered'){ this.scount = 6; }
  }

}
