import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../core/services/authentication.service'

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoteComponent implements OnInit {
	oid:number;
	order:any = {'companies':[],'sale':'','reference':''};
	user:any = {'id':0, 'username':''};
	// date: any = new Date();
	date = new UntypedFormControl(new Date());
	initial:string;
	note:string;

	constructor(@Inject(MAT_DIALOG_DATA) public data:any, private auth:AuthenticationService, private dailogRef:MatDialogRef<NoteComponent>, private spinner: NgxSpinnerService, private _snackBar: MatSnackBar){
		this.oid = data['oid'];
		// this.order = data['order'];
		// console.log(this.date);
		this.auth.getAPI('order/'+this.oid+'/').subscribe(result => {
			this.order = result;
			this.initial = this.order['initial'];
			this.note = this.order['note'];
			// this.date = formatDate(this.order['initial_date'], "dd-MM-yyyy", 'en-GB');
			this.date = new UntypedFormControl(this.order['initial_date']);

			// this.date = new Date();
			if(this.date.value == null){
				this.date = new UntypedFormControl(new Date());
			}
			// console.log(this.date.value);
		})
	}

	ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem('profile'));
	}

	dateChange(event: any) {
		// this.date = formatDate(event.value, 'yyyy-MM-dd', 'en');
		this.date = new UntypedFormControl(event.value);
		// console.log(this.date);
	}

	add(){
		this.spinner.show();
		// let dt = formatDate(this.date, "yyyy-MM-dd", 'en-GB');
		let dt = new UntypedFormControl(this.date).value.value;
		dt = formatDate(dt, "yyyy-MM-dd", 'en-GB');
		this.auth.patchAPI('order/'+this.oid+'/',{'initial_date':dt,'initial':this.initial,'note':this.note}).subscribe(result => {
			this._snackBar.open("Note added successfully",'', {duration: 5000, panelClass: ['blue-snackbar']});
			this.dailogRef.close(result);
			this.spinner.hide();
		})
	}

	delete(){
		if(confirm("Are you sure, you want to delete this Note?")){
			this.spinner.show();
			this.auth.patchAPI('order/'+this.oid+'/',{'initial_date':null,'initial':'','note':''}).subscribe(result => {
				this._snackBar.open("Note deleted successfully",'', {duration: 5000, panelClass: ['blue-snackbar']});
				this.dailogRef.close();
				this.spinner.hide();
			})
		}
	}

	cancel(){
		this.dailogRef.close();
	}

}
