<main class="reports">
	<app-topnav></app-topnav>
	<section class="container-fluid">
		<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
		<div class="row">
			<div class="col-3">
				<div class="leftbar">
					<div>
						<input id="file-id" type="file"(change)="upload($event)" style="display:none" />
	        			<label for="file-id" class="mat-focus-indicator md-button md-raised md-primary mat-raised-button mat-button-base upload">Upload Excel</label>
					</div>
					<div>
						<mat-form-field appearance="outline" style="width: 46%;margin:0 2%;float: left;">
			                <mat-label>From Date:</mat-label>
			                <input matInput [matDatepicker]="pstart" [(ngModel)]='sdate' (dateChange)="dchange($event)">
			                <mat-datepicker-toggle matSuffix [for]="pstart"></mat-datepicker-toggle>
			                <mat-datepicker #pstart></mat-datepicker>
			            </mat-form-field>
			            <mat-form-field appearance="outline" style="width: 46%;margin:0 2%;float: left;">
			                <mat-label>To Date:</mat-label>
			                <input matInput [matDatepicker]="pend" [(ngModel)]='edate' (dateChange)="dchange($event)">
			                <mat-datepicker-toggle matSuffix [for]="pend"></mat-datepicker-toggle>
			                <mat-datepicker #pend></mat-datepicker>
			            </mat-form-field>
					</div>
					<div><a mat-raised-button href="{{durl}}" color="warn">EXPORT DATE RANGE ORDERS</a></div>
					<div><a mat-raised-button href="{{purl}}" color="warn">EXPORT DATE RANGE PODS</a></div>
					<div>
						<mat-form-field appearance="fill" style="width:90%;margin: 0 auto;">
						  <mat-label>STATUS FILTER</mat-label>
						  <mat-select [(ngModel)]="fstatus" (selectionChange)="statusFilter()">
						    <mat-option value=''>NO Filter</mat-option>
						    <mat-option value='Received'>Received</mat-option>
						    <mat-option value='Production'>Production</mat-option>
						    <mat-option value='Despatch'>Despatch</mat-option>
						    <mat-option value='Distribution'>Distribution</mat-option>
						    <mat-option value='On Its Way'>On Its Way</mat-option>
						    <mat-option value='Delivered'>Delivered</mat-option>
						  </mat-select>
						</mat-form-field>
					</div>
					<div>
						<form>
						<mat-form-field style="width:90%;margin: 0 auto;">
						    <mat-label>COMPANY FILTER</mat-label>
						    <input type="text"
						           placeholder="Search Company"
						           aria-label="Company"
						           matInput
           						   [formControl]="myControl"
						           [matAutocomplete]="auto">
						    <mat-autocomplete autoActiveFirstOption (optionSelected)="selectCmp($event)" #auto="matAutocomplete">
						     <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option.account}}</mat-option>
						    </mat-autocomplete>
						  </mat-form-field>
						</form>
					</div>
					<div>
						<mat-form-field style="width:90%;margin: 0 auto;">
						  <mat-label>FAST SEARCH</mat-label>
						  <input matInput (keyup)="applyFilter($event)" placeholder="CLEA100" #input>
						</mat-form-field>
					</div>
					<div>
						<mat-form-field appearance="fill" style="width:90%;margin: 0 auto;">
						  <mat-label>Change selected Orders to this Status</mat-label>
						  <mat-select [(ngModel)]="status">
						    <mat-option value='Received'>Received</mat-option>
						    <mat-option value='Production'>Production</mat-option>
						    <mat-option value='Despatch'>Despatch</mat-option>
						    <mat-option value='Distribution'>Distribution</mat-option>
						    <mat-option value='On Its Way'>On Its Way</mat-option>
						    <mat-option value='Delivered'>Delivered</mat-option>
						  </mat-select>
						</mat-form-field>
						<button mat-raised-button (click)="ustatus()" class="customer">UPDATE STATUS</button>
					</div>
					<div>
						<button mat-raised-button (click)="customer()" class="customer">Notify Selected Customers</button> 
						<!-- <button mat-raised-button (click)="distributor()" class="distributor">NOTIFY DISTRIBUTOR</button> -->
					</div>
				</div>
			</div>
			<div class="col-9">
				<div class="table-responsive rightbar">
					<table mat-table [dataSource]="dataSource" matSort (matSortChange)="SortChange($event)" class="table-bordered" summary="Orders list">

						<!-- Checkbox Column -->
				        <ng-container matColumnDef="select">
				          <th mat-header-cell *matHeaderCellDef style="padding-left: 25px;">
				            <mat-checkbox (change)="$event ? toggleAllRows() : null"
				                          [checked]="selection.hasValue() && isAllSelected()"
				                          [indeterminate]="selection.hasValue() && !isAllSelected()"
				                          [aria-label]="checkboxLabel()">
				            </mat-checkbox>
				          </th>
				          <td mat-cell *matCellDef="let row">
				            <mat-checkbox (click)="$event.stopPropagation()"
				                          (change)="$event ? selection.toggle(row) : null"
				                          [checked]="selection.isSelected(row)"
				                          [aria-label]="checkboxLabel(row)">
				            </mat-checkbox>
				          </td>
				        </ng-container>
				        <!-- DISTRIBUTOR CODE Column -->
						<ng-container matColumnDef="area">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Code</th>
						  <td mat-cell *matCellDef="let row">{{row.companies.account}}</td>
						</ng-container>

						<!-- TRACKING NO. Column -->
						<ng-container matColumnDef="sale">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Sr. No</th>
						  <!-- <td mat-cell *matCellDef="let row"> {{row.sale}} </td> -->
						  <td mat-cell *matCellDef="let row">						  	
						  	{{row.sale}}
						  </td>
						</ng-container>

						<ng-container matColumnDef="despatch">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Distribution</th>
						  <td mat-cell *matCellDef="let row">{{row.reference}}</td>
						  <!-- <td mat-cell *matCellDef="let row">{{row.despatch}}</td> -->
						</ng-container>

						<ng-container matColumnDef="myca">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">MYCA Link</th>
						  <td mat-cell *matCellDef="let row"><a href="{{auth.baseUrl}}/order/{{row.identifier}}/" target="_blank"> MYCA Link</a></td>
						  <!-- <td mat-cell *matCellDef="let row">{{row.despatch}}</td> -->
						</ng-container>

						<ng-container matColumnDef="surname">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Reference</th>
						  <td mat-cell *matCellDef="let row">{{row.reference}}</td>
						</ng-container>

						<ng-container matColumnDef="w3w">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Postcode</th>
						  <td mat-cell *matCellDef="let row">{{row.postcode}}</td>
						</ng-container>

						<!-- POST CODE Column -->
						<ng-container matColumnDef="customer">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Delivery Name</th>
						  <td mat-cell *matCellDef="let row">{{row.name}}</td>
						</ng-container>

						<!-- POST CODE Column -->
						<ng-container matColumnDef="address">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Email</th>
						  <td mat-cell *matCellDef="let row" style="text-align: left;max-width: 95px;overflow: hidden;">{{row.email}}</td>
						</ng-container>

						<!-- POST CODE Column -->
						<ng-container matColumnDef="postcode">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Mobile</th>
						  <td mat-cell *matCellDef="let row">{{row.mobile}}</td>
						</ng-container>

						<!-- POST CODE Column -->
						<ng-container matColumnDef="weight">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Estimated</th>
						  <td mat-cell *matCellDef="let row">{{row.delivery}}</td>
						</ng-container>

						<!-- POST CODE Column -->
						<ng-container matColumnDef="parcel">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 60px;">Date</th>
						  <td mat-cell *matCellDef="let row">{{row.date}}</td>
						</ng-container>

						<!-- POST CODE Column -->
						<ng-container matColumnDef="loaded">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 60px;">Status</th>
						  <td mat-cell *matCellDef="let row">
						  	<p [ngClass]="{'redbg':row.loaded > 0}">{{row.status}}</p>
						  </td>
						</ng-container>

						<!-- POST CODE Column -->
						<!-- <ng-container matColumnDef="comments">
						  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> COMMENTS </th>
						  <td mat-cell *matCellDef="let row"> {{row.comments}} </td>
						</ng-container> -->

						<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

						<!-- Row shown when there is no matching data. -->
						<tr class="mat-row" *matNoDataRow>
						  <td class="mat-cell" colspan="12">No data matching the filter</td>
						</tr>
					  </table>

					  <mat-paginator [pageSizeOptions]="[150, 200, 500, 1000, 1500, 2000]"></mat-paginator>
				</div>
			</div>
		</div>
	</section>
</main>