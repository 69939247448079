import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

	transform(items: any[], searchText: any[], key: string): unknown {
		if(key == 'pinme'){
			let pin = false;
			for (var p = 0; p < items.length; ++p){
				if(items[p].id == searchText){
					pin = true;
				}
			}
			return pin;
		}
		if (searchText == undefined || searchText.length == 0) {
			return items;
		}
		if (!searchText) {
			return items;
		}
		console.log(items, searchText, key);
		return items.filter(it => {
			for (var i = 0; i < searchText.length; ++i) {
				if (it[key] == searchText[i]) {
					return it[key];
				}
			}
		});
		// return items;
	}

}
