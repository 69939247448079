import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent implements OnInit {
  title:any
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<ConfirmComponent>) {
    this.title=data['title'];
  }

  ngOnInit(): void {}

  dialogclose(status:any){
    this.dialogRef.close(status);
  }
}
