<main class="ratings">
	<app-topnav></app-topnav>
	<section class="container maintenance" *ngIf="maintenance">
        <div><img src="assets/images/503.png" alt="MyClearAmber"></div>
        <h1>{{maintdata.title}}</h1>
        <h3>{{maintdata.message}}</h3>
    </section>
    <section class="container" *ngIf="!maintenance">
		<div class="dash">
            <mat-form-field appearance="outline">
                <mat-label>Choose a date range</mat-label>
                <input matInput [matDatepicker]="picker" (dateChange)="dateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
		<div class="dash">
            <highcharts-chart 
            [Highcharts]="highcharts"
            [options]="chartOptions"
            style="width: 100%; height: 400px; display: block;"
            ></highcharts-chart>
        </div>
	</section>
</main>
