import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Router, NavigationEnd } from '@angular/router';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, throwError } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { FilterPipe } from '../../../pipes/custom/filter.pipe';
import { FilterArrayPipe } from '../../../pipes/custom/filter-array.pipe';
import { SortByPipe } from '../../../pipes/custom/sort-by.pipe';
import { NoteComponent } from '../../../shared/dialogs/note/note.component';
import { StatusComponent } from '../../../shared/dialogs/status/status.component';
import { BulkChatComponent } from '../../../shared/dialogs/bulk-chat/bulk-chat.component';

@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class OrderlistComponent implements OnInit {
	@Output() public PresentOrder = new EventEmitter();
	@Output() public Emergency = new EventEmitter();
	@Input()  public uorder: number;

	orders:any = [];
	allOrders:any = [];
	distributors:any = [];
	groups:any = [];
	tags:any = [];
	order:any;
	currentIndex:number = 0;
	current:any;
	search:string;
	status:string;
	group:string;
	company:any = [];
	selectOrders:any = [];
	tag:string;
	unread:boolean;
	read:boolean;
	remaining = 0;
	limit = 500;
	maxchat = 6000;
	url:string='chatorder';
	next:any = 'limit='+this.limit+'&offset=0';
	// next:any;
	user:any;
	groupForm = new UntypedFormControl();
	distForm = new UntypedFormControl();
	tagForm = new UntypedFormControl();
	statusForm = new UntypedFormControl();

	visible = true;
	selectable = true;
	removable = true;
	allComplete = false;
	cad:boolean = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	fruitCtrl = new UntypedFormControl();
	filteredCompanies: Observable<string[]>;
	companies: string[] = [];
	allCompanies: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

	@ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;

	constructor(private auth:AuthenticationService, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar, private filterPipe:FilterPipe, private filterArray:FilterArrayPipe, private sortBy:SortByPipe, private spinner: NgxSpinnerService){
		this.spinner.show();
		if(this.router.url == '/cad'){
			this.cad = false;
			this.url = 'cadorder';
			// alert("CAD PAGE"+ this.url);
		}
		// setTimeout(() => {
		// 	this.spinner.hide();
		// }, 5000);
		// this.auth.getAPI('chatorder/?limit='+this.limit).subscribe(result => {
		// 	this.orders = result['results'];
		// 	this.allOrders = result['results'];
		// 	if (result['next']!=null) {
		// 		this.next = result['next'].split('?')[1];
		// 	}
		// 	if (result['count'] > this.limit) {
		// 		this.remaining = result['count']-this.limit;
		// 	}else{
		// 		this.remaining = 0;
		// 	}
		// 	// this.singleOrder(this.orders[0].id);
		// 	// var fil = this.filterPipe.transform(this.orders,'RK','reference');
		// 	// console.log(fil);
		// 	this.current = this.orders[0];
		// 	this.PresentOrder.emit(this.orders[0]);
		// 	this.spinner.hide();
		// });
		this.chatOrders();
		this.distLoad();
		// this.groupLoad();
		this.tagLoad();

		// this.filteredCompanies = this.fruitCtrl.valueChanges.pipe(
		// 	startWith(null),
		// 	map((fruit: string | null) => fruit ? this._filter(fruit) : this.allCompanies.slice()));
	}

	refresh(){
		console.log("Refresh");
		this.auth.getAPI(this.url+'/?limit=2').subscribe(result => {
				// console.log(this.orders);
				// console.log(result);
				// console.log(this.allOrders);
				this.orders = [].concat(this.orders, result['results']);
				this.allOrders = [].concat(this.allOrders, result['results']);
				// console.log(this.orders);
				// console.log(result);
				// console.log(this.allOrders);
			});
	}

	changeStatus(){
		// console.log(this.selectOrders);
		const dialogRef = this.dialog.open(StatusComponent,{
			data:{
				tags:this.tags,
				orders:this.selectOrders,
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			// console.log(result);
			if(result != undefined){
				// console.log("Call api to change");
				this.allComplete = true;
				this.auth.postAPI('tag-change/',{'ids':this.selectOrders,'tag':result}).subscribe(data => {
					// console.log(data);
					this.selectOrders = [];
					// this.orders = this.allOrders;
					// this.allComplete = true;
					this.allComplete = false;
				});
			}
			// let indexx = this.orders.findIndex(x => x.id === result.id);
			// this.orders[indexx] = result;
		});
	}

	bulkMsg(){
		if(this.selectOrders.length == 0){
			this._snackBar.open("Please Select orders to send Bulk Message", '', {duration: 3000, panelClass: ['redPAddSnackbar']});
			return false;
		}
		const dialogRef = this.dialog.open(BulkChatComponent,{
			data:{
				// tags:this.tags,
				orders:this.selectOrders,
			}
		});

		// dialogRef.afterClosed().subscribe(result => {
		// 	if(result != undefined){
		// 		this.allComplete = true;
		// 		this.auth.postAPI('tag-change/',{'ids':this.selectOrders,'tag':result}).subscribe(data => {
		// 			this.selectOrders = [];
		// 			this.allComplete = false;
		// 		});
		// 	}
		// });
	}

	setOrder(e,oid){
		if(e){
			this.selectOrders.push(oid);
		}else{
			this.selectOrders.pop(oid);
		}
		// console.log(this.selectOrders);
	}

	chatOrders(){
		// this.auth.getAPI('chatorderes/?'+this.next)
		this.auth.getAPI(this.url+'/?'+this.next)
			.pipe(catchError(error => {
				if (error.status == 503) {
					// this.maintenance = true;
					// this.maintdata = error.error.error;
					console.log(error.error.error);
					this.Emergency.emit(error.error.error);
					this.spinner.hide();
				}
				return throwError(error);
			}))
			.subscribe(result => {
				this.orders = [].concat(this.orders, result['results']);
				this.allOrders = [].concat(this.allOrders, result['results']);
				if (result['next']!=null) {
					this.next = result['next'].split('?')[1];
					this.remaining = result['count']-parseInt(this.next.split('=')[2]);
				}
				if (this.remaining < 0) {
					this.remaining = 0;
				}
				if(parseInt(this.next.split('=')[2]) == this.limit || this.current == undefined){
					// this.current = this.orders[0];
					// this.PresentOrder.emit(this.orders[0]);
				}
				this.spinner.hide();
				if(this.allOrders.length != result['count'] && this.allOrders.length < this.maxchat){
					this.chatOrders();
				}else{
					this.remaining = 0;
					this.distLoad(true);
					this.tagLoad(true);
				}
			});
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our comp
		if ((value || '').trim()) {
			// this.companies.push(value.trim());
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.fruitCtrl.setValue(null);
	}

	remove(comp: string): void {
		const index = this.companies.indexOf(comp);

		if (index >= 0) {
		  this.companies.splice(index, 1);
		  this.company.splice(index, 1);
		}
		// this.orders = this.filterCompany(this.company);
		this.orders = this.filterArray.transform(this.allOrders,this.company, 'company');
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.company.push(event.option.value.id);
		// this.companyFilter();
		// this.orders = this.filterCompany(this.company);
		this.orders = this.filterArray.transform(this.allOrders,this.company, 'company');
		// console.log(this.company);
		// this.companies.push(event.option.viewValue);
		this.companies.push(event.option.value);
		this.fruitInput.nativeElement.value = '';
		this.fruitCtrl.setValue(null);
		this.PresentOrder.emit({'id':0});
	}

	private _filter(value: any): any[] {
		var filterValue = value;
		if (typeof(value) == 'object') {
			filterValue = value['account'].toLowerCase();
		}else{
			filterValue = value.toLowerCase();
		}

		return this.allCompanies.filter(comp => comp['account'].toLowerCase().indexOf(filterValue) === 0);
	}

	// filterCompany(searchText){
	// 	if (searchText == undefined || searchText.length == 0) {
	// 		return this.orders;
	// 	}
	// 	return this.orders.filter(it => {
	// 		for (var i = 0; i < searchText.length; ++i) {
	// 			if (it['company'] == searchText[i]) {
	// 				return it['company'];
	// 			}
	// 		}
	// 	});
	// }

	ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem('profile'));
		this.status = JSON.parse(localStorage.getItem('status'));
	}

	ngOnChanges(changes: SimpleChanges): void {
		// this.id = changes.uorder.currentValue.id;
		// this.singleOrder(this.id);
		// console.log("Updated Order ", changes.uorder.currentValue);
		if (this.uorder != undefined) {
			// this.orders[this.currentIndex] = this.uorder;
			// let indexx = this.orders.findIndex(x => x.id === this.uorder.id);
			let indexx = this.orders.findIndex(x => x.id === changes.uorder.currentValue.id);
			this.orders[indexx] = this.uorder;
			this.current = this.uorder;
		}
	}

	readCount(){
		this.read = !this.read;
	}

	unreadCount(){
		this.unread = !this.unread;
	}

	singleOrder(id){
		// this.PresentOrder.emit(id);
		// this.auth.getAPI('order/'+id+'/').subscribe(result => {
		// 	this.order = result;
		// 	console.log(this.order);
		// });
	}

	orderCall(obj, i){
		// console.log(obj,i);
		this.currentIndex = i;
		this.current = obj;
		this.PresentOrder.emit(obj);
		// this.PresentOrder.emit(obj.id);
	}

	distLoad(reload:boolean = false){
		if(localStorage.getItem('companylist') == null || reload){
			this.auth.getAPI('distributors/?limit=500').subscribe(result => {
				this.distributors = result['results'];
				localStorage.setItem('companylist', JSON.stringify(result['results']) );
				// this.company = JSON.parse(localStorage.getItem('company'));
				// if (this.company == null) {
				// 	this.company = [];
				// }
				this.allCompanies = result['results'];
				this.filteredCompanies = this.fruitCtrl.valueChanges.pipe(startWith(null), map((comp: any | null) => comp ? this._filter(comp) : this.allCompanies.slice()));
			});
		}else{
			this.distributors = JSON.parse(localStorage.getItem('companylist'));
			this.allCompanies = JSON.parse(localStorage.getItem('companylist'));
			this.filteredCompanies = this.fruitCtrl.valueChanges.pipe(startWith(null), map((comp: any | null) => comp ? this._filter(comp) : this.allCompanies.slice()));
		}
	}

	// groupLoad(){
	// 	this.auth.getAPI('group/').subscribe(result => {
	// 		this.groups = result['results'];
	// 		this.group = JSON.parse(localStorage.getItem('group'));
	// 	});
	// }

	tagLoad(reload:boolean = false){
		if(localStorage.getItem('taglist') == null || reload){
			this.auth.getAPI('tag/').subscribe(result => {
				this.tags = result['results'];
				this.tag = JSON.parse(localStorage.getItem('tag'));
				localStorage.setItem('taglist', JSON.stringify(result['results']) );
			});
		}else{
			this.tags = JSON.parse(localStorage.getItem('taglist'));
			this.tag = JSON.parse(localStorage.getItem('tag'));
		}
	}

	more(param){
		this.auth.getAPI(this.url+'/?'+param).subscribe(result => {
			// this.orders.concat(result['results']);
			this.orders = [].concat(this.orders, result['results']);
			this.allOrders = [].concat(this.allOrders, result['results']);
			if (result['next'] != null) {
				this.next = result['next'].split('?')[1];
			}
			this.remaining -= this.limit;
			if (this.remaining < 0) {
				this.remaining = 0;
			}
		});
	}

	statusFilter(){
		if (this.status.length == 0) {
			localStorage.removeItem('status');
		}else{
			this.PresentOrder.emit({'id':0});
			localStorage.setItem('status', JSON.stringify(this.status) );
		}
	}

	groupFilter(){
		// console.log(this.group);
		if (this.group.length == 0) {
			localStorage.removeItem('group');
		}else{
			this.PresentOrder.emit({'id':0});
			localStorage.setItem('group', JSON.stringify(this.group) );
		}
	}

	companyFilter(){
		if (this.company.length == 0) {
			localStorage.removeItem('company');
		}else{
			this.PresentOrder.emit({'id':0});
			localStorage.setItem('company', JSON.stringify(this.company) );
		}
	}

	tagFilter(){
		if (this.tag.length == 0) {
			localStorage.removeItem('tag');
		}else{
			this.PresentOrder.emit({'id':0});
			localStorage.setItem('tag', JSON.stringify(this.tag) );
		}
	}

	note(ordr){
		// console.log("Note edit");
		const dialogRef = this.dialog.open(NoteComponent,{
			data:{
				oid:ordr.id,
				order:ordr,
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			// console.log(result);
			let indexx = this.orders.findIndex(x => x.id === result.id);
			this.orders[indexx] = result;
		});
	}

}
