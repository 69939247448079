import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl,FormArray } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../../core/services/authentication.service';

export interface OrderData {
  id: string;
  area: any;
  sale: string;
  despatch: string;
  customer: string;
  address: string;
  town: string;
  postcode: string;
  weight: string;
  parcel: string;
  loaded: string;
  over: string;
  note: string;
  scanned: string;
  delivered: string;
  vanno: string;
}

@Component({
  selector: 'app-cad-bulk-edit',
  templateUrl: './cad-bulk-edit.component.html',
  styleUrls: ['./cad-bulk-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CadBulkEditComponent implements OnInit {
  dataSource: MatTableDataSource<OrderData>;
  rkData:any;
  areas:any;
  displayedColumns = ['sale', 'despatch', 'customer', 'address', 'town', 'postcode', 'weight', 'parcel', 'loaded', 'over', 'note', 'scanned', 'delivered', 'area'];
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, private fb:FormBuilder, private auth:AuthenticationService, private dialogRef:MatDialogRef<CadBulkEditComponent>){
    this.rkData = data['data'];
    this.areas = data['aread'];
    this.dataSource = new MatTableDataSource(this.rkData);
  }

  ngOnInit(): void {}

  saveBulk(){
    // console.log(this.rkData);
    this.auth.postAPI('update-rkdata/',this.rkData).subscribe(result =>{
      this.dialogRef.close({'status':true,'data':result['results']});
    })
  }
}
