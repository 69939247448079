import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CadComponent } from './cad/cad.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ScoreComponent } from './score/score.component';
import { RatingComponent } from './rating/rating.component';
import { ResponseComponent } from './response/response.component';
import { CadrkdataComponent } from './cadrkdata/cadrkdata.component';
import { CadrkdashComponent } from './cadrkdash/cadrkdash.component';
import { ReportsComponent } from './reports/reports.component'
import { LivechatComponent } from './livechat/livechat.component';

const routes: Routes = [
	{
		path: 'login/:token/:page',
		component: LoginComponent
	},
	{
		path: 'message',
		component: HomeComponent
	},
	{
		path: 'chat',
		component: LivechatComponent
	},
	{
		path: 'cad',
		component: CadComponent
	},
	{
		path: 'score',
		component: ScoreComponent
	},
	{
		path: 'ratings',
		component: RatingComponent
	},
	{
		path: 'response',
		component: ResponseComponent
	},

	{
		path: 'cadrkdata',
		component: CadrkdataComponent
	},

	{
		path: 'cadrkdash',
		component: CadrkdashComponent
	},

	{
		path: 'reports',
		component: ReportsComponent
	},
	{
		path: '',
		component: DashboardComponent
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }