<main class="score">
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner>
    
	<app-topnav></app-topnav>
	<section class="container maintenance" *ngIf="maintenance">
        <div><img src="assets/images/503.png" alt="MyClearAmber"></div>
        <h1>{{maintdata.title}}</h1>
        <h3>{{maintdata.message}}</h3>
    </section>
    <section class="container" *ngIf="!maintenance">
		<div class="dash">
            <mat-form-field appearance="outline">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" (dateChange)="dateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w100">
				<mat-label>Filter Colour Code</mat-label>
				<mat-select [(ngModel)]="color" (selectionChange)="colorFilter()">
					<mat-option [value]="''">-- SELECT COLOUR --</mat-option>
					<mat-option *ngFor="let group of colorList" [value]="group">{{group.name}}</mat-option>
				</mat-select>
			</mat-form-field>
        </div>
        <mat-card class="graphcard">
            <h2>LIVE SCORE</h2>
            <div class="dash">
                <!-- <highcharts-chart 
                [Highcharts]="highcharts"
                [options]="chartOptions"
                style="width: 100%; height: 500px; display: block;"
                ></highcharts-chart> -->
                <div id="weekChart"></div>
            </div>
            <div class="dash">
                <!-- <highcharts-chart 
                [Highcharts]="highcharts"
                [options]="chartHourly"
                style="width: 100%; height: 500px; display: block;"
                ></highcharts-chart> -->
                <div id="hourChart"></div>
            </div>
        </mat-card>
	</section>
    <app-response [dateChange]="filterDate"></app-response>
</main>
