<!-- <highcharts-chart 
  [Highcharts]="Highcharts"
  [options]="chartOptions"
  [(update)]="updateFlag"
  [oneToOne]="oneToOneFlag"
  [callbackFunction]="chartCallback"
  style="width: 100%; height: 200px; display: block;"
></highcharts-chart> -->
<div id="received" *ngIf="who == 'received' "style="width: 100%; height: 142px; display: block;"></div>
<div id="production" *ngIf="who == 'production' "style="width: 100%; height: 142px; display: block;"></div>
<div id="despatch" *ngIf="who == 'despatch' "style="width: 100%; height: 142px; display: block;"></div>
<div id="distribution" *ngIf="who == 'distribution' "style="width: 100%; height: 142px; display: block;"></div>
<div id="onway" *ngIf="who == 'onway' "style="width: 100%; height: 142px; display: block;"></div>
<div id="delivered" *ngIf="who == 'delivered' "style="width: 100%; height: 142px; display: block;"></div>
<!-- <div #chart></div> -->