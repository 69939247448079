<main>
	<app-topnav></app-topnav>
	<section class="container maintenance" *ngIf="maintdata.title!=''">
		<div><img src="assets/images/503.png" alt="MyClearAmber"></div>
		<h1>{{maintdata.title}}</h1>
		<h3>{{maintdata.message}}</h3>
	</section>
	<section class="container-fluid" *ngIf="maintdata.title==''">
		<div class="row">
			<div class="col-md-4">
				<app-clist (PresentOrder)="sorder = $event;status($event);" (Emergency)="maintdata = $event;" [uorder]="norder"></app-clist>
			</div>
			<div class="col-md-8">
				<div class="row shopping">
					<div class="col-md-2">
						<div class="title">RECEIVED</div>
						<div class="record">
							<!-- <img title="Received" alt="Received" src="assets/images/RECEIVED1.gif" class="img-fluid"> -->
							<img title="Received" alt="Received" [src]="scount == 1 ? 'assets/images/RECEIVED1.gif' : 'assets/images/RECEIVED2.png'" class="img-fluid">
							<i class="fas fa-chevron-right ambclr"></i>
						</div>
					</div>
					<div class="col-md-2">
						<div class="title">PRODUCTION</div>
						<div class="record">
							<img title="Received" alt="Received" [src]="scount == 2 ? 'assets/images/MANUFACTURING2.gif' : (scount > 2 ? 'assets/images/MANUFACTURING3.png' : 'assets/images/MANUFACTURING1.png')" class="img-fluid">
							<i class="fas fa-chevron-right ambclr"></i>
						</div>
					</div>
					<div class="col-md-2">
						<div class="title">DESPATCHED</div>
						<div class="record">
							<img title="Received" alt="Received" [src]="scount == 3 ? 'assets/images/PACKAGING2.gif' : (scount > 3 ? 'assets/images/PACKAGING3.png' : 'assets/images/PACKAGING1.png')" class="img-fluid">
							<i class="fas fa-chevron-right ambclr"></i>
						</div>
					</div>
					<div class="col-md-2">
						<div class="title">DISTRIBUTION</div>
						<div class="record">
							<img title="Received" alt="Received" [src]="scount == 4 ? 'assets/images/DESPATCH2.gif' : (scount > 4 ? 'assets/images/DESPATCH3.png' : 'assets/images/DESPATCH1.png')" class="img-fluid">
							<i class="fas fa-chevron-right ambclr"></i>
						</div>
					</div>
					<div class="col-md-2">
						<div class="title">OUT FOR DELIVERY</div>
						<div class="record">
							<img title="Received" alt="Received" [src]="scount == 5 ? 'assets/images/ON-THE-ROAD2.gif' : (scount > 5 ? 'assets/images/ON-THE-ROAD3.png' : 'assets/images/ON-THE-ROAD1.png')" src="assets/images/ON-THE-ROAD1.png" class="img-fluid">
							<i class="fas fa-chevron-right ambclr"></i>
						</div>
					</div>
					<div class="col-md-2">
						<div class="title">EST DEL</div>
						<div class="record">
							<img title="Received" alt="Received" [src]="scount == 6 ? 'assets/images/DELIVERED2.gif' : (scount > 6 ? 'assets/images/DELIVERED3.png' : 'assets/images/DELIVERED1.png')" class="img-fluid">
							<i class="fas fa-chevron-right ambclr"></i>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<app-corder (UpdateOrder)="norder = $event;status($event);" [order]="sorder"></app-corder>
					</div>
					<div class="col-md-8">
						<app-cchat [order]="sorder"></app-cchat>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>
