import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	sorder = 0;
	norder:any;
	maintenance:boolean = false;
  maintdata:any = {'title':'','message':''}
	constructor(){}

	ngOnInit(): void {}

}
