import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";

import { NqsComponent } from '../../../shared/dialogs/nqs/nqs.component';
import { NoteComponent } from '../../../shared/dialogs/note/note.component';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-order-display',
  templateUrl: './order-display.component.html',
  styleUrls: ['./order-display.component.scss']
})
export class OrderDisplayComponent implements OnInit {
	@Output() public UpdateOrder = new EventEmitter();
	@Input()  public order: number;
	detail:any = {'sale':'', 'identifier':'', 'companies':[], 'tags':{'title':''}};
	id:number;
	tags:any = [];
	tag:string;
	status:string;
	edits:boolean = false;
	cad:boolean = true;
	user:any;

	constructor(public auth:AuthenticationService, private router: Router, public dialog: MatDialog, private spinner: NgxSpinnerService){
		this.tagLoad();
		if(this.router.url == '/cad'){
			this.cad = false;
		}
	}

	ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem('profile'));
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.id = changes.order.currentValue?.id;
		this.singleOrder(this.id);
		// console.log(this.order);
	}

	singleOrder(id){
		if (id > 0) {
			this.auth.getAPI('chatorderes2/'+id+'/').subscribe(result => {
				this.detail = result;
				this.status = this.detail.status;
				this.tag = this.detail.tag;
			});
			// this.detail = this.order;
			// this.status = this.detail.status;
			// this.tag = this.detail.tag;
		}else{
			this.detail = {read:false,chat:true,identifier:'',sale:'',reference:'',postcode:'',name:'',email:'',mobile:'',date:'',status:'',tags:{},companies:{}};
		}
	}

	statusUpdate(){
		this.spinner.show();
		this.auth.patchAPI('order/'+this.id+'/', {'status':this.status}).subscribe(result => {
			this.detail = result;
			this.UpdateOrder.emit(this.detail);
			this.spinner.hide();
			console.log(this.detail);
		});
	}

	tagUpdate(){
		this.spinner.show();
		this.auth.patchAPI('order/'+this.id+'/', {'tag':this.tag}).subscribe(result => {
			this.detail = result;
			this.UpdateOrder.emit(this.detail);
			this.spinner.hide();
		});
	}

	mark(val){
		this.spinner.show();
		this.auth.patchAPI('order/'+this.id+'/', {'read':val}).subscribe(result => {
			this.detail = result;
			this.UpdateOrder.emit(this.detail);
			this.spinner.hide();
		});
	}

	customer(){
		this.spinner.show();
		let cusdata = [{'id[]':this.id},{'notified':true}];
		this.auth.postJson('notify-customer/', cusdata).subscribe(result => {
			alert(result['results']);
			this.spinner.hide();
		});
	}

	distributor(){
		this.spinner.show();
		this.auth.postJson('notify-company/', [{'id[]':this.id}]).subscribe(result => {
			alert(result['results']);
			this.spinner.hide();
		});
	}

	// tagLoad(){
	// 	this.auth.getAPI('tag/').subscribe(result => {
	// 		this.tags = result['results'];
	// 	});
	// }

	tagLoad(reload:boolean = false){
		if(localStorage.getItem('taglist') == null || reload){
			this.auth.getAPI('tag/').subscribe(result => {
				this.tags = result['results'];
				this.tag = JSON.parse(localStorage.getItem('tag'));
				localStorage.setItem('taglist', JSON.stringify(result['results']) );
			});
		}else{
			this.tags = JSON.parse(localStorage.getItem('taglist'));
			this.tag = JSON.parse(localStorage.getItem('tag'));
		}
	}

	nqs(){
		const dialogRef = this.dialog.open(NqsComponent,{
			data:{
				oid:this.id,
				order:this.detail,
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
		});
	}

	editMode(){
		this.edits = !this.edits;
	}

	saveMode(){
		this.spinner.show();
		this.auth.patchAPI('order/'+this.id+'/', {'postcode':this.detail.postcode, 'name':this.detail.name, 'email':this.detail.email, 'distribution':this.detail.distribution, 'mobile':this.detail.mobile}).subscribe(result => {
			this.detail = result;
			this.UpdateOrder.emit(this.detail);
			this.spinner.hide();
		});
		this.edits = !this.edits;
	}

	pin(){
		this.spinner.show();
		this.auth.postForm('../../pinme/', [{'order':this.id}]).subscribe(result => {
			// console.log(result);
			// this.detail = result;
			// this.UpdateOrder.emit(this.detail);
			this.spinner.hide();
		});
		this.auth.patchAPI('order/'+this.id+'/', {'pin':!this.detail.pin}).subscribe(result => {
			this.detail = result;
			this.UpdateOrder.emit(this.detail);
			this.spinner.hide();
		});
	}

	note(){
		// console.log(this.order);
		const dialogRef = this.dialog.open(NoteComponent,{
			data:{
				oid:this.id,
				order:this.detail,
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result != undefined){
				this.detail = result;
			}
		});
	}

}
