<mat-card class="details">
	<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner> -->
	<mat-card-header>
		<mat-card-title>ORDER DETAILS</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<table class="table table-striped table-bordered table-sm" summary="Orders Detail">
			<tbody>
				<tr><th scope="col">Tracking Number</th><td><a href="{{auth.baseUrl}}/order/{{detail.identifier}}/" target="_blank">{{detail.sale}}</a></td></tr>
				<tr *ngIf="cad"><th scope="col">Distributor</th><td>{{detail.companies.account}}</td></tr>
				<tr><th scope="col">Reference</th><td>{{detail.reference}}</td></tr>
				<tr>
					<th scope="col">Postcode</th>
					<td>
						<p *ngIf="!edits">{{detail.postcode}} <mat-icon matSuffix (click)="editMode()">edit</mat-icon></p>
						<mat-form-field appearance="outline" class="w100" *ngIf="edits">
							<mat-label>Email</mat-label>
							<input matInput type="text" [(ngModel)]="detail.postcode" autocomplete="off">
							<mat-icon matSuffix (click)="saveMode()">done_outline</mat-icon>
						</mat-form-field>
					</td>
				</tr>
				<tr>
					<th scope="col">Delivery Name</th>
					<td>
						<p *ngIf="!edits">{{detail.name}} <mat-icon matSuffix (click)="editMode()">edit</mat-icon></p>
						<mat-form-field appearance="outline" class="w100" *ngIf="edits">
							<mat-label>Name</mat-label>
							<input matInput type="text" [(ngModel)]="detail.name" autocomplete="off">
							<mat-icon matSuffix (click)="saveMode()">done_outline</mat-icon>
						</mat-form-field>
					</td>
				</tr>
				<tr *ngIf="cad">
					<th scope="col">Email</th>
					<td>
						<p *ngIf="!edits">{{detail.email}} <mat-icon matSuffix (click)="editMode()">edit</mat-icon></p>
						<mat-form-field appearance="outline" class="w100" *ngIf="edits">
							<mat-label>Email</mat-label>
							<input matInput type="text" [(ngModel)]="detail.email" autocomplete="off">
							<mat-icon matSuffix (click)="saveMode()">done_outline</mat-icon>
						</mat-form-field>
					</td>
				</tr>
				<tr *ngIf='user.is_staff && user.companies.account == "CLEA100" && cad'>
					<th scope="col">Distribution Code</th>
					<td>
						<p *ngIf="!edits">{{detail.distribution}} <mat-icon matSuffix (click)="editMode()">edit</mat-icon></p>
						<mat-form-field appearance="outline" class="w100" *ngIf="edits">
							<mat-label>Distribution Code</mat-label>
							<input matInput type="text" [(ngModel)]="detail.distribution" autocomplete="off">
							<mat-icon matSuffix (click)="saveMode()">done_outline</mat-icon>
						</mat-form-field>
					</td>
				</tr>
				<tr>
					<th scope="col">Telephone</th>
					<td>
						<p *ngIf="!edits">{{detail.mobile}} <mat-icon matSuffix (click)="editMode()">edit</mat-icon></p>
						<mat-form-field appearance="outline" class="w100" *ngIf="edits">
							<mat-label>Email</mat-label>
							<input matInput type="text" [(ngModel)]="detail.mobile" autocomplete="off">
							<mat-icon matSuffix (click)="saveMode()">done_outline</mat-icon>
						</mat-form-field>
					</td>
				</tr>
				<tr><th scope="col">Date Order Received</th><td>{{detail.date}}</td></tr>
				<tr>
					<th scope="col">Status</th>
					<td>
						<mat-form-field appearance="fill" class="w100" *ngIf='user.role == "Employee"'>
							<mat-label>SELECT STATUS</mat-label>
							<mat-select [(value)]="status" (selectionChange)="statusUpdate()" *ngIf='user.role == "Employee"'>
								<mat-option value="Received">Received</mat-option>
								<mat-option value="Production">Production</mat-option>
								<mat-option value="Despatch">Despatch</mat-option>
								<mat-option value="Distribution">Distribution</mat-option>
								<mat-option value="On Its Way">On Its Way</mat-option>
								<mat-option value="Delivered">Delivered</mat-option>
							</mat-select>
						</mat-form-field>
						<p *ngIf='user.role != "Employee"'>{{detail.status}}</p>
					</td>
				</tr>
				<tr>
					<th scope="col">Colour Code</th>
					<td>
						<mat-form-field appearance="fill" class="w100" *ngIf='user.role == "Employee"'>
							<mat-label>SELECT COLOUR CODE</mat-label>
							<mat-select [(value)]="tag" (selectionChange)="tagUpdate()">
								<mat-option *ngFor="let tag of tags" [value]="tag.id">{{tag.title}}</mat-option>
							</mat-select>
						</mat-form-field>
						<p *ngIf='user.role != "Employee"'>{{detail.tags.title}}</p>
					</td>
				</tr>
				<!-- <tr>
					<th scope="col">Mark As Unread</th>
					<td>
						<button mat-raised-button *ngIf="detail.read" (click)="mark(false)" class="unread">Unread</button>
						<button mat-raised-button disabled *ngIf="!detail.read" class="unread dunread">Unread</button>
					</td>
				</tr>
				<tr>
					<th scope="col">Mark As Read</th>
					<td>
						<button mat-raised-button *ngIf="!detail.read" (click)="mark(true)" class="unread">Read</button>
						<button mat-raised-button disabled *ngIf="detail.read" class="unread dunread">Read</button>
					</td>
				</tr> -->
				<tr *ngIf='user.role == "Employee" && cad'><th scope="col">NQS</th><td><button mat-raised-button (click)="nqs()" class="nqs">NQS</button></td></tr>
				<tr *ngIf='user.role == "Employee"'><th scope="col"></th><td><a mat-raised-button href="{{auth.baseUrl}}/pod/{{detail.identifier}}/" target="_blank" class="nqs">VIEW POD <span *ngIf="detail.initial"  style="border: 2px solid #56595a;padding: 2px;border-radius: 8px;">{{detail.initial}}</span></a></td></tr>
				<!-- <tr *ngIf='user.role == "Employee" && cad'><th scope="col"></th><td><button mat-raised-button (click)="note()" class="nqs">FOLLOW UP NOTE <span *ngIf="detail.initial"  style="border: 2px solid #56595a;padding: 2px;border-radius: 8px;">{{detail.initial}}</span></button></td></tr> -->
				<!-- <tr *ngIf='user.role == "Employee"'><th scope="col"></th><td><button mat-raised-button (click)="distributor()" class="distributor">NOTIFY DISTRIBUTOR</button></td></tr> -->
				<tr *ngIf="cad"><th scope="col"></th><td><button mat-raised-button (click)="customer()" class="customer">NOTIFY CUSTOMER</button></td></tr>
				<!-- <tr *ngIf='user.role == "Employee" && cad'><th scope="col"></th><td><button mat-raised-button (click)="pin()" class="nqs" [ngClass]="{'pinned':detail.pinned}"><span *ngIf="!detail.pinned">PIN CHAT</span><span *ngIf="detail.pinned">UNPIN CHAT</span> <mat-icon aria-hidden="false" aria-label="PIN" style="float: right;margin-top: 8px;">push_pin</mat-icon></button></td></tr> -->
				<tr *ngIf="cad" style="background-color: #fff;">
					<th scope="col"></th>
					<td>
						<img *ngIf="detail.swift == 'SWIFT' " src="assets/images/swift.png" alt="swift" class="img-fluid">
						<img *ngIf="detail.swift == 'SWIFT PLUS' " src="assets/images/swiftpl.png" alt="swift plus" class="img-fluid">
					</td>
				</tr>
			</tbody>
		</table>
	</mat-card-content>
</mat-card>