<mat-card class="messages">
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner>
	<mat-card-header [ngClass]="{ 'swift': order?.swift == 'SWIFT' || order?.swift == 'SWIFT PLUS' }">
		<mat-card-title>
			<div *ngIf="order?.swift != 'SWIFT' && order?.swift != 'SWIFT PLUS'">CHAT DETAILS</div>
			<div *ngIf="order?.swift == 'SWIFT'"><img src="assets/images/SWIFT-white.png" alt="swift"> PRIORITY CHAT DETAILS</div>
			<div *ngIf="order?.swift == 'SWIFT PLUS'"><img src="assets/images/SWIFTPLUS-white.png" alt="swift"> PRIORITY CHAT DETAILS</div>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content #scrollMe>
		<div class="row chat" *ngFor="let chat of chats">
			<div class="col-12">
				<div class="message" [ngClass]="{'me': chat.role == 'Employee', 'me': chat.auto_response > 0, 'me': chat.user > 0, 'distributor': chat.role == 'Distributor', 'podmessage': chat.pod, 'hrwmessage': chat.hrw }">
					<div class="info">
						<!-- <div *ngIf="chat.image != ''" class="memoji"><img src="{{chat.image}}" alt=""></div> -->
						<span class="redial" [ngStyle]="{'background':(chat.cateams?.bgcolor || '')}"></span> 
						<span *ngIf="!chat.pod && !chat.hrw && chat.who != null">{{chat.who}}</span> 
						<span *ngIf="!chat.pod && !chat.hrw && chat.who == null">Customer</span> 
						<span *ngIf="chat.pod || chat.hrw">{{chat.cateams.title}}</span> 
						<span>{{chat.timestamp | date :  "dd-MM-y h:mm a"}}</span>
					</div>
					<div class="arowup" [ngStyle]="{'color':(chat.cateams?.bgcolor || '')}"><span class="material-icons">arrow_drop_up</span></div>
					<div *ngIf="chat.file == null" class="display" [ngStyle]="{'background':(chat.cateams?.bgcolor || ''), 'color':(chat.cateams?.color || '#ffffff')}" [innerHTML]="chat.message">{{chat.message | displayLink}}</div>
					<div *ngIf="chat.file != null" class="display">
						<img [src]="chat.files?.file" alt="{{chat.files?.title}}" (click)="openImage(chat.files?.file)">
					</div>
					<div class="deliver"><span *ngIf="chat.role == 'Employee' && chat.cdeliver" class="material-icons" [ngClass]="{'read': chat.cread}" >done_all</span></div>
				</div>
			</div>
			<!-- <div class="col-2"></div> -->
		</div>
	</mat-card-content>
	<mat-card-actions class="msgbox" [ngClass]="{ 'swift': order?.swift == 'SWIFT' || order?.swift == 'SWIFT PLUS' }">
		<div class="row">
			<div class="col-md-11">
				<mat-list class="phrases" *ngIf="pdisplay">
					<mat-list-item (click)="openPhrase()">Add Phrase</mat-list-item>
					<mat-list-item *ngFor="let phrase of phrases | filterPipe:phword:'title'" (click)="fillPhrase(phrase.content)">\{{phrase.title}}</mat-list-item>
					<!-- <mat-list-item>Item 3</mat-list-item> -->
				</mat-list>
				<mat-list class="phrases" *ngIf="typing">
					<mat-list-item>Typing...</mat-list-item>
				</mat-list>
				<form>
					<mat-form-field class="w100">
						<mat-label>Chat with our Help Team:</mat-label>
						<textarea matInput placeholder="Please can you help me..." [(ngModel)]="message" (keyup)="phrase()" (keyup.enter)="sendMessage()" [ngModelOptions]="{standalone: true}"></textarea>
						<mat-icon matSuffix (click)="uploadFile()" style="font-size: 34px;">attach_file</mat-icon>
						<input type="file" name="file" id="messagefile" #messagefile (change)="fileUpload($event)" style="display: none;">
					</mat-form-field>
				</form>
			</div>
			<!-- <div class="col-md-1">
				<button mat-raised-button (click)="mark(true)"><mat-icon aria-hidden="false" aria-label="Example home icon">attach_file</mat-icon></button>
			</div> -->
			<div class="col-md-1">
				<button mat-raised-button (click)="sendMessage()" style="padding: 5px;margin: 0 4px;"><mat-icon aria-hidden="false" aria-label="send icon">send</mat-icon></button>
			</div>
		</div>
	</mat-card-actions>
</mat-card>