import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dailogRef:MatDialogRef<ImageComponent>){
		// console.log(data);
	}

	ngOnInit(): void {
	}

}
