import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform(items: any[], sortColumn: any, direction = 'asc'): unknown {
  	if (sortColumn == undefined) {
		return items;
	}
	let sortedArray = (items || []).sort((a,b)=>{
        if(a[sortColumn] > b[sortColumn]){
          return (direction === 'desc') ? 1 : -1;
          return (direction === 'asc') ? -1 : 1;
        }
        if(a[sortColumn] < b[sortColumn]){
          return (direction === 'desc') ? -1 : 1;
          return (direction === 'asc') ? 1 : -1;
        }
        return 0;
      })
    return sortedArray;
    // return items;
  }

}
