<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner>

<div class="phrase">
	<div><h3>Add Phrase</h3></div>
	<hr>
	<div>
		<form class="w100">
			<mat-form-field class="w100">
			    <mat-label>Phrase content</mat-label>
			    <textarea matInput placeholder="" [(ngModel)]="content" [ngModelOptions]="{standalone: true}"></textarea>
			    <mat-hint align="start"><strong>The phrase that you'll add will be visible to all employees in you organisation</strong> </mat-hint>
			</mat-form-field>
		  	<mat-form-field class="w100" style="margin-top: 50px;">
			    <mat-label>Assign Tag \</mat-label>
			    <input matInput placeholder="thanks" [(ngModel)]="title" [ngModelOptions]="{standalone: true}">
		  	</mat-form-field>
		</form>
	</div>
	<hr>
	<div>
		<button mat-raised-button (click)="save()">ADD PHRASE</button>
	</div>
</div>