import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from '../../../core/services/authentication.service';
import { DisplayLinkPipe } from '../../../pipes/custom/display-link.pipe';
import { ImageComponent } from '../../../shared/dialogs/image/image.component';
import { PhraseComponent } from '../../../shared/dialogs/phrase/phrase.component';

@Component({
  selector: 'app-cchat',
  templateUrl: './cchat.component.html',
  styleUrls: ['./cchat.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CchatComponent implements OnInit {
  @Input()  public order: any;
  @ViewChild('messagefile') messagefileST: ElementRef<HTMLElement>;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  phrases:any = [];
  chats:any = [];
  next:any;
  oid:number;
  wsm : WebSocketSubject<any>;
  url:string='chates';
  message:string;
  phword:string;
  limit = 50;
  user:any;
  pdisplay:boolean = false;
  file:any;
  mtype = 'typing'
  typing:boolean = false;
  cad:boolean = false;
  typecount:number = 0;

  constructor(private auth:AuthenticationService, private router: Router, private spinner: NgxSpinnerService, public dialog: MatDialog, private displayLink:DisplayLinkPipe){}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('profile'));
    this.auth.getAPI('phrase/').subscribe(result => {
      this.phrases = result['results'];
    });
    this.removeTyping();
    if(this.router.url == '/cad'){
      this.url = 'cadchat';
      this.cad = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.oid = changes.order.currentValue?.id;
    this.oldChat(this.oid);
  }

  oldChat(id){
    if (id > 0) {
      this.spinner.show();
      this.auth.getAPI(this.url+'/?limit='+this.limit+'&order='+id).subscribe(result => {
        this.chats = result['results'].reverse();
        if (result['next']!=null) {
          this.next = result['next'].split('?')[1];
        }
        this.scrollToBottom(true);
        this.spinner.hide();
      });

      // this.wsm = webSocket('ws://mcatest.grokurv.co.uk/ws/chat/'+this.oid+'/');
      if (this.wsm != undefined) {
        // console.log(this.wsm);
        this.wsm.complete();
        // this.wsm.close.bind(this.wsm);
        // webSocket.close();
      }
      this.wsm = webSocket(this.auth.socketUrl+'/ws/chat/'+this.oid+'/');
      this.wsm.subscribe({
        next : (data) => {
          // this.scrollToBottom(true);
          // console.log(data);
          this.mtype = data['type'];
          if (this.oid != data['order']) {
            return false; // Block here if order not match
          }
          if (this.mtype == 'typing') {
            if (data['role'] == 'Customer') {
              this.typing = true;
            }
            return false;
          }else{
            console.log(data);
          }
          if (data['user']=='') {
            for (var i = 0; i < this.chats.length; ++i){
              this.chats[i]['cdeliver'] = true;
              this.chats[i]['cread'] = true;
            }
          }
          if (data['attachment'] == '') {
            this.chats.push({'message':data['message'], 'timestamp':data['timestamp'], 'user':data['user'], 'image':data['image'], 'role':data['role'], 'who':data['firstname']+' '+data['lastname'], 'cdeliver':false, 'cread':false,});
          }else{
            this.chats.push({'files':{'file':data['attachment']}, 'file':data['user'], 'message':data['message'], 'timestamp':data['timestamp'], 'user':data['user'], 'image':data['image'], 'role':data['role'], 'who':data['firstname']+' '+data['lastname'], 'cdeliver':false, 'cread':false,})
          }
          // this.auth.getAPI('/load/group/messages/?id='+this.groups.group_id+'&lastest_message_id='+data.messageObject.group_message_id+'&star=true').subscribe(result => {
          //  this.gchats = result['data']['results'];
          //  console.log(this.gchats);
          // });
        },
        // error : console.log,
        error : (err) => { console.log(err) },
        complete : () => { console.log() }
      })
      // this.sendMessage();
    }
    else{
      this.chats = [];
    }
  }

  sendMessage(){
    // this.wsm.next({"message":this.message});
    if(this.message.trim().length > 0){
      this.wsm.next({"message":this.message,"type":'message',"token":localStorage.getItem("token"),"cad":this.cad});
      this.scrollToBottom(true);
      this.message = '';
    }
  }

  uploadFile(){
    let el: HTMLElement = this.messagefileST.nativeElement;
    el.click();
  }

  fileUpload(event){
    this.spinner.show();
    let file = event.target.files[0];
    var size = (file.size*0.000001).toFixed(4);
    var type = file.type.split("/");
    var name = file.name.split(".")[0];
    if(type[0] == 'image'){ type = 'Image'; }
    if(type[0] == 'video'){ type = 'Video'; }
    if(type[0] == 'audio'){ type = 'Audio'; }
    if(type[0] == 'application'){ type = 'Document'; }
    this.auth.postForm('file/', [{'file':file}, {'title':name}, {'size':size}, {'extension':type}, {'user':this.user.id}]).subscribe(result => {
      this.file = result;
      this.wsm.next({"message":'chatfile:'+this.file.id, "token":localStorage.getItem("token"),"cad":this.cad, "type":"message"});
      this.scrollToBottom(true);
      this.spinner.hide();
    });
  }

  phrase(){
    if (this.message.indexOf('\\') == 0) {
      this.phword = this.message.replace('\\','');
      this.pdisplay = true;
    }else{
      this.pdisplay = false;
      this.phword = '';
    }
    if (this.typecount != this.message.length) {
      this.wsm.next({"message":'',"type":'typing',"token":localStorage.getItem("token"),"cad":this.cad});
    }
    this.typecount = this.message.length;
  }

  fillPhrase(content){
    this.message = content;
    this.pdisplay = false;
  }

  openPhrase(){
    const dialogRef = this.dialog.open(PhraseComponent,{
      data:{
        // order:this.detail,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      this.auth.getAPI('phrase/').subscribe(result => {
        this.phrases = result['results'];
      });
    });
  }

  openImage(url){
    const dialogRef = this.dialog.open(ImageComponent,{
      data:{
        url:url,
        // order:this.detail,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  more(param){
    this.auth.getAPI('chat/?order='+this.oid+'&'+param).subscribe(result => {
      // this.orders.concat(result['results']);
      this.chats = [].concat(this.chats, result['results']);
      this.next = result['next'].split('?')[1];
    });
  }

  scrollToBottom(old=false): void {
    // console.log("Scroll Bottom");
    // try {
    //  this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    // } catch(err) { }
    var demo = 0;
    if (old) {
      demo = this.myScrollContainer.nativeElement.scrollTop;
    }
    let scrollToTop = window.setInterval(() => {
      if (this.myScrollContainer.nativeElement.scrollTop == demo) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }else{
        window.clearInterval(scrollToTop);
      }
    }, 500);

  }

  removeTyping(){
    let scrollToTop = window.setInterval(() => {
      // window.clearInterval(scrollToTop);
      this.typing = false;
    }, 5000);
  }

}
