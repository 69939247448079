import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-bulk-chat',
  templateUrl: './bulk-chat.component.html',
  styleUrls: ['./bulk-chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BulkChatComponent implements OnInit {
  orders:any = [];
  message:string;
  constructor(private auth:AuthenticationService, @Inject(MAT_DIALOG_DATA) public data:any, private dailogRef:MatDialogRef<BulkChatComponent>){
    this.orders = data['orders'];
    console.log(this.orders);
  }

  ngOnInit(): void {
  }

  statusNew(){
    // console.log(this.tag);
  }

  send(){
    // this.dailogRef.close(this.tag);
    if(this.message.length < 1 || this.orders.length < 1){
      return false;
    }
    this.auth.postAPI('bulk-message/',{'ids':this.orders,'message':this.message}).subscribe(result => {
      this.message = "";
      this.dailogRef.close(result);
      console.log(result)
    });
  }

  cancel(){
    this.dailogRef.close();
  }

}
