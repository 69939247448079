<main class="response">
	<!-- <app-topnav></app-topnav> -->
	<section class="container maintenance" *ngIf="maintenance">
		<div><img src="assets/images/503.png" alt="MyClearAmber"></div>
		<h1>{{maintdata.title}}</h1>
		<h3>{{maintdata.message}}</h3>
	</section>
	<section class="container" *ngIf="!maintenance">
		<div class="row">
			<div class="col-md-12">
				<mat-card class="graphcard">
					<h2>AVERAGE RESPONSE TIME</h2>
					<div class="dash">
						<div id="weekAvg"></div>
					</div>
					<div class="dash">
						<div id="hourAvg"></div>
					</div>
				</mat-card>
			</div>
		</div>
	</section>
</main>