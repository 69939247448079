import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import * as Highcharts from 'highcharts';
// import { Chart } from 'highcharts';

import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class RatingComponent implements OnInit, AfterViewInit {
	Highcharts: typeof Highcharts = Highcharts; // required
	chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
	highcharts = Highcharts;
   	chartOptions = {   
      chart: {
        type: 'areaspline'
      },
      title: {
        text: 'Average rating for current week'
      },
      subtitle : {
         style: {
            position: 'absolute',
            right: '0px',
            bottom: '10px'
         }
      },
      legend : {
         layout: 'vertical',
         align: 'left',
         verticalAlign: 'top',
         x: -150,
         y: 100,
         floating: true,
         borderWidth: 1,
        
         backgroundColor:'#fdd7af'
      },
      xAxis:{
         categories: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'] 
      },
      yAxis : {
         title: {
            text: 'Number of stars'
         }
      },
      tooltip : {
         shared: true, valueSuffix: ' star'
      },
      plotOptions : {
         area: {
            fillOpacity: 0.5 
         }
      },
      credits:{
        enabled: false
      },
	  colors: ['#f78e1d', '#50B432', '#ED561B', '#DDDF00'],
      series: [
         {
            name: 'Ratings',
            data: [0, 0, 0, 0, 0, 0, 0],
			backgroundColor:'#fdd7af'
         }
      ]
   	};
	
	graphData:any;
	profile:any;
   maintenance:boolean = false;
   maintdata:any = {'title':'','message':''}
	constructor(private router:Router, private auth:AuthenticationService, private spinner: NgxSpinnerService){
		// this.auth.getAPI('rating/').subscribe(result => {
		// 	this.graphData = result;
		// 	console.log(this.graphData, this.chartOptions.series[0].data);
		// 	this.chartOptions.series[0].data = this.graphData['ratings'];
		// 	console.log(this.chartOptions.series[0].data);
		// });
		this.profile = JSON.parse(localStorage.getItem('profile'));
      if(this.profile.role!="Employee"){
         this.router.navigate(['']);
      }
	}

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		this.auth.getAPI('rating/').pipe(catchError(error => {
               if (error.status == 503) {
                  this.maintenance = true;
                  this.maintdata = error.error.error;
                  this.spinner.hide();
               }
               return throwError(error);
            })
         ).subscribe(result => {
			this.graphData = result;
			// this.chartOptions.series[0].data = this.graphData['ratings'];
			this.updateChart();
		});
	}

	dateChange(event: any) {
		var start = formatDate(event.value, 'yyyy-MM-dd', 'en');
		console.log(start);
		this.auth.getAPI('rating/?date='+start).subscribe(result => {
			this.graphData = result;
			// this.chartOptions.series[0].data = this.graphData['ratings'];
			this.updateChart();
		});
	}

	updateChart(){
		this.chartOptions = {   
			chart: {
			  type: 'areaspline'
			},
			title: {
			  text: 'Average rating for current week'
			},
			subtitle : {
			   style: {
				  position: 'absolute',
				  right: '0px',
				  bottom: '10px'
			   }
			},
			legend : {
			   layout: 'vertical',
			   align: 'left',
			   verticalAlign: 'top',
			   x: -150,
			   y: 100,
			   floating: true,
			   borderWidth: 1,
			  
			   backgroundColor:'#fdd7af'
			},
			xAxis:{
			   categories: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'] 
			},
			yAxis : {
			   title: {
				  text: 'Number of stars'
			   }
			},
			tooltip : {
			   shared: true, valueSuffix: ' star'
			},
			plotOptions : {
			   area: {
				  fillOpacity: 0.5 
			   }
			},
			credits:{
			  enabled: false
			},
			colors: ['#f78e1d', '#50B432', '#ED561B', '#DDDF00'],
			series: [
			   {
				  name: 'Ratings',
				  data: this.graphData['ratings'],
				  backgroundColor:'#fdd7af'
			   }
			]
		};
	}

}
