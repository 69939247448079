import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
@Injectable()
export class FilterPipe implements PipeTransform {

	// transform(value: unknown, ...args: unknown[]): unknown {
	// 	return null;
	// }
	transform(items: any[], searchText: any, key: string): unknown {
		// console.log(items, searchText, key);
		if (searchText == undefined) {
			return items;
		}
		if (!items) {
			return [];
		}
		if (isNaN(searchText)) {
			// console.log("Text search");
			searchText = searchText.toLocaleLowerCase();
		}

		if (key == 'count'){
			return items.filter(it => {
				if (it['read'].toString() == searchText.toString()) {
					return it;
				}
			}).length;
		}

		if ((key == 'read' || key == 'unread') && searchText == false){
			return items;
		}

		// if (key == 'unread') {
		// 	key = 'read';
		// 	searchText = false;
		// }
		// console.log("Last filter apply", key, searchText);
		return items.filter(it => {
			if (key == 'unread' && searchText == true && it['read'] == false) {
				return it;
			}
			if (isNaN(searchText)) {
				return it[key].toLocaleLowerCase().includes(searchText);
			}else{
				return it[key];
			}
		});
	}

}
