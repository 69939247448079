import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import * as Highcharts from 'highcharts';

import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ScoreComponent implements OnInit, AfterViewInit {

	graphOriginal = {'colours':[], 'series':[]};
	graphData = {'colours':[], 'series':[]};
	color:any;
	filterDate:any;
	colorList:any;
	profile:any;
	maintenance:boolean = false;
	maintdata:any = {'title':'','message':''}

	weekChart:any = {
	  chart: {
		type: 'areaspline'
	  },
	  title: {
		text: 'Number of Chats Per Day'
	  },
	  subtitle : {
		 style: {
			position: 'absolute',
			right: '0px',
			bottom: '10px'
		 }
	  },
	  legend : {
		 layout: 'vertical',
		 align: 'left',
		 verticalAlign: 'top',
		 x: -250,
		 y: 100,
		 floating: true,
		 borderWidth: 1,
		
		 backgroundColor:'#fdd7af'
	  },
	  xAxis:{
		 categories: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
		 plotBands: [{ // visualize the weekend
			from: 4.5,
			to: 6.5,
			color: '#fcd2a2'
		 }]
	  },
	  yAxis : {
		 title: {
			text: 'Number of chats'
		 }
	  },
	  tooltip : {
		 shared: true, valueSuffix: ' chats'
	  },
	  plotOptions : {
		 area: {
			fillOpacity: 0.5 
		 }
	  },
	  credits:{
		enabled: false
	  },
	  colors: this.graphData['colours'],
	  series: this.graphData['series']
	}

	hourChart:any = {   
		 chart: {
		   type: 'line'
		 },
		 title: {
		   text: 'Number of Chats For Each Hour'
		 },
		 legend : {
			layout: 'vertical',
			align: 'left',
			verticalAlign: 'top',
			x: -250,
			y: 100,
			floating: true,
			borderWidth: 1,
			backgroundColor:'#fdd7af'
		 },
		 xAxis:{
			categories: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24']
		 },
		 yAxis : {
			title: {
			   text: 'Number of chats'
			}
		 },
		 tooltip : {
			shared: true, valueSuffix: ' chats'
		 },
		 plotOptions : {
			// area: {fillOpacity: 0.5},
			dataLabels: {enabled: true}
		 },
		 credits:{
		   enabled: false
		 },
		 colors: this.graphData['colours'],
		 series: this.graphData['daily']
	};
	constructor(private router:Router, private auth:AuthenticationService, private spinner: NgxSpinnerService){
	  this.profile = JSON.parse(localStorage.getItem('profile'));
	  if(this.profile.role!="Employee"){
		 this.router.navigate(['']);
	  }
	}

	ngOnInit(): void {}

	ngAfterViewInit(){
		this.spinner.show();
		this.auth.getAPI('score/')
			.pipe(catchError(error => {
				if (error.status == 503) {
					this.maintenance = true;
					this.maintdata = error.error.error;
					this.spinner.hide();
				}
				return throwError(error);
			}))
			.subscribe(result => {
				this.graphOriginal = result;
				this.graphData = result;
				this.colorList = result['series'];
				this.updateChart();
				this.spinner.hide();
			});
	}

	dateChange(event: any) {
	  this.spinner.show();
		this.filterDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
		this.auth.getAPI('score/?date='+this.filterDate).subscribe(result => {
			this.graphOriginal = result;
			this.graphData = result;
			// this.chartOptions.series[0].data = this.graphData['ratings'];
			this.updateChart();
		 this.spinner.hide();
		});
	}

	colorFilter(){
	  this.auth.getAPI('score/?date='+this.filterDate).subscribe(result => {
			this.graphOriginal = result;
			this.graphData = result;
		 const original = this.graphOriginal['series'];
		 original.forEach((element,index) => {
			if(this.color['name'] == original[index]['name'] || this.color['name'] == undefined){
			   this.graphData['series'][index] = original[index];
			}else{
			   this.graphData['series'][index] = {'name':original[index].name, 'data':[0,0,0,0,0,0,0]};
			}
		 });
			this.updateChart();
		});
	}

	updateChart(){
	  // this.chartOptions = {   
	  //    chart: {
	  //      type: 'areaspline'
	  //    },
	  //    title: {
	  //      text: 'Number of Chats Per Day'
	  //    },
	  //    subtitle : {
	  //       style: {
	  //          position: 'absolute',
	  //          right: '0px',
	  //          bottom: '10px'
	  //       }
	  //    },
	  //    legend : {
	  //       layout: 'vertical',
	  //       align: 'left',
	  //       verticalAlign: 'top',
	  //       x: -250,
	  //       y: 100,
	  //       floating: true,
	  //       borderWidth: 1,
		   
	  //       backgroundColor:'#fdd7af'
	  //    },
	  //    xAxis:{
	  //       categories: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
	  //       plotBands: [{ // visualize the weekend
	  //          from: 4.5,
	  //          to: 6.5,
	  //          color: '#fcd2a2'
	  //       }]
	  //    },
	  //    yAxis : {
	  //       title: {
	  //          text: 'Number of chats'
	  //       }
	  //    },
	  //    tooltip : {
	  //       shared: true, valueSuffix: ' chats'
	  //    },
	  //    plotOptions : {
	  //       area: {
	  //          fillOpacity: 0.5 
	  //       }
	  //    },
	  //    credits:{
	  //      enabled: false
	  //    },
	  //    colors: this.graphData['colours'],
	  //    series: this.graphData['series']
	  // };

	  // this.chartHourly = {   
	  //    chart: {
	  //      type: 'line'
	  //    },
	  //    title: {
	  //      text: 'Number of Chats For Each Hour'
	  //    },
	  //    legend : {
	  //       layout: 'vertical',
	  //       align: 'left',
	  //       verticalAlign: 'top',
	  //       x: -250,
	  //       y: 100,
	  //       floating: true,
	  //       borderWidth: 1,
	  //       backgroundColor:'#fdd7af'
	  //    },
	  //    xAxis:{
	  //       categories: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24']
	  //    },
	  //    yAxis : {
	  //       title: {
	  //          text: 'Number of chats'
	  //       }
	  //    },
	  //    tooltip : {
	  //       shared: true, valueSuffix: ' chats'
	  //    },
	  //    plotOptions : {
	  //       // area: {fillOpacity: 0.5},
	  //       dataLabels: {enabled: true}
	  //    },
	  //    credits:{
	  //      enabled: false
	  //    },
	  //    colors: this.graphData['colours'],
	  //    series: this.graphData['daily']
	  // };

	  this.weekChart.colors = this.graphData['colours'];
	  this.weekChart.series = this.graphData['series'];
	  Highcharts.chart('weekChart',this.weekChart);

	  this.hourChart.colors = this.graphData['colours'];
	  this.hourChart.series = this.graphData['daily'];
	  Highcharts.chart('hourChart',this.hourChart);
	}

}
