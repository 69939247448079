<div class="cadform">
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

	<div class="row" style="max-height: 600px; overflow-y: scroll;">
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>AREA</mat-label>
				<mat-select [(value)]="cadform.area">
					<mat-option *ngFor="let area of areas" [value]="area.id">{{area.title}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>VAN</mat-label>
				<mat-select [(value)]="cadform.van">
					<mat-option *ngFor="let van of vans" [value]="van.id">{{van.title}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="outline" class="w100">
	            <mat-label>Despatch Date:</mat-label>
	            <input matInput [matDatepicker]="picker" (dateChange)="dchange($event)">
	            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	            <mat-datepicker #picker></mat-datepicker>
	        </mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>SO Number</mat-label>
				<input type="number" [(ngModel)]="cadform.sale" matInput placeholder="Sale" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Customer</mat-label>
				<input [(ngModel)]="cadform.customer" matInput placeholder="customer" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Address</mat-label>
				<textarea [(ngModel)]="cadform.address" matInput placeholder="Address..." rows="3"></textarea>
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Town</mat-label>
				<input [(ngModel)]="cadform.town" matInput placeholder="town" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Post Code</mat-label>
				<input [(ngModel)]="cadform.postcode" matInput placeholder="customer" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Weight (kg)</mat-label>
				<input [(ngModel)]="cadform.weight" matInput placeholder="weight" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>TOTAL PARCELS</mat-label>
				<input [(ngModel)]="cadform.parcel" matInput placeholder="parcel" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>PARCELS LOADED</mat-label>
				<input [(ngModel)]="cadform.loaded" matInput placeholder="loaded" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>OVER 4M?</mat-label>
				<input [(ngModel)]="cadform.over" matInput placeholder="over" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Note</mat-label>
				<textarea [(ngModel)]="cadform.note" matInput placeholder="Note..." rows="3"></textarea>
			</mat-form-field>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12"><button mat-raised-button (click)="add()">ADD CON</button></div>
	</div>
</div>
