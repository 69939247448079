<div class="nqs">
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

	<div class="row">
		<div class="col-md-4">
			<img src="assets/images/nqs.png" alt="NQS" class="img-fluid">
		</div>
		<div class="col-md-8 heading">
			<div>DIST CODE: {{order.companies.account}}</div>
			<div>ORDER NO: {{order.sale}}</div>
			<div>DIST REF: {{order.reference}}</div>
		</div>
	</div>
	<hr>
	<div class="row">
		<!-- <div class="col-md-4">NQS Type:</div> -->
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>NQS Type</mat-label>
				<mat-select [(value)]="ntype" (selectionChange)="nqsType()">
					<mat-option *ngFor="let type of nqstype" [value]="type">{{type.title}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>REASON</mat-label>
				<mat-select [(value)]="nreason">
					<mat-option *ngFor="let reason of nqsreason" [value]="reason.id">{{reason.title}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Product Group's:</mat-label>
				<mat-select [(ngModel)]="ngroup" multiple>
					<mat-option *ngFor="let group of nqsgroup" [value]="group.id">{{group.title}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>COST</mat-label>
				<input [(ngModel)]="cost" matInput placeholder="Cost" value="">
			</mat-form-field>
			<div *ngIf="cost>1999.99" style="color: red; margin: -15px 0 10px 5px;"> £1999.99 is the maximum entry value for this field </div>
		</div>
		<div class="col-md-12" *ngIf="ntype.title != 'CREDIT'">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Replacement SO No</mat-label>
				<input [(ngModel)]="replacement" matInput placeholder="Replacement SO No" value="">
			</mat-form-field>
		</div>
		<div class="col-md-12">
			<mat-form-field appearance="fill" class="w100">
				<mat-label>Note</mat-label>
				<textarea [(ngModel)]="note" matInput placeholder="Note..." rows="3"></textarea>
			</mat-form-field>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12"><button mat-raised-button (click)="add()" [disabled]="nqsdisable || cost>1999.99">ADD NQS</button></div>
	</div>
</div>
