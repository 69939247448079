import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { HighchartsChartModule } from 'highcharts-angular';
// import { RouterModule } from '@angular/router';
import { CoreRoutingModule } from './core-routing.module'

import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSortModule} from '@angular/material/sort';
import {AsyncPipe} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ScoreComponent } from './score/score.component';
import { RatingComponent } from './rating/rating.component';
import { ResponseComponent } from './response/response.component';
import { CadComponent } from './cad/cad.component';
import { CadrkdataComponent } from './cadrkdata/cadrkdata.component';
import { CadrkdashComponent } from './cadrkdash/cadrkdash.component';
import { ReportsComponent } from './reports/reports.component';
import { LivechatComponent } from './livechat/livechat.component';



@NgModule({
  declarations: [HomeComponent, LoginComponent, DashboardComponent, ScoreComponent, RatingComponent, ResponseComponent, CadComponent, CadrkdataComponent, CadrkdashComponent, ReportsComponent, LivechatComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    HighchartsChartModule,
    // RouterModule,
    CoreRoutingModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSortModule,
    AsyncPipe,
    MatAutocompleteModule,
    SharedModule
  ]
})
export class CoreModule { }
