<header>
	<div class="container">
		<div class="row" *ngIf="url == '/cadrkdata' || url == '/cadrkdash'">
			<div class="col-3"><img src="assets/images/logo.png" alt="MyClearAmber"></div>
			<div class="col-9">
				<div class="global">
					<h2>GLOBAL ALERT</h2>
					<div class="notice">
						<div *ngFor="let glbl of global">
							<div *ngFor="let alrt of glbl.alerts" [innerHTML]="alrt.message"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="url != '/cadrkdata' && url != '/cadrkdash'">
			<div class="col-12">
				<div *ngFor="let img of headerImage">
					<img src="{{img.image}}" alt="" style="width: 100%;">
				</div>
			</div>
		</div>
		<div class="row" *ngIf="url != '/cadrkdata' && url != '/cadrkdash'">
			<div class="col-12">
				<div class="global">
					<h2>GLOBAL ALERT</h2>
					<div class="notice">
						<div *ngFor="let glbl of global">
							<div *ngFor="let alrt of glbl.alerts" [innerHTML]="alrt.message"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<style type="text/css">
		.menu .nav-item{ font-size:{{fsize}}px; }
	</style>
	<div class="menu">
		<nav class="navbar navbar-expand-lg navbar-dark bg-dark" [ngStyle]="{'font-size':fsize}">
		  <div class="container">
			<!-- <a class="navbar-brand" href="#">Navbar</a> -->
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
			  <span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarNav">
			  <ul class="navbar-nav">
				<li class="nav-item" *ngIf='user.dashboard || (!user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only)'>
				  <a class="nav-link" aria-current="page" routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive #rla="routerLinkActive" [ngClass]="[rla.isActive == true ? 'active': '']">DASHBOARD</a>
				</li>
				<!-- <li class="nav-item" *ngIf='user.role == "Employee" && !user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" href="{{auth.baseUrl}}/excel/">UPLOADS</a>
				</li> -->
				<li class="nav-item" *ngIf='user.cadrk_view || user.cadrk_edit || user.cadrk_edit_only || user.cadrk_view_only || user.cadrk_delete || user.cadrk_edit_van_only'>
				  <a class="nav-link" routerLink="/cadrkdata" routerLinkActive="active">CAD RK DATA</a>
				</li>
				<li class="nav-item" *ngIf='user.cadrk_view || user.cadrk_edit || user.cadrk_edit_only || user.cadrk_view_only || user.cadrk_delete || user.cadrk_edit_van_only'>
				  <a class="nav-link" routerLink="/cadrkdash" routerLinkActive="active">CAD RK DASH</a>
				</li>
				<!-- <li class="nav-item" *ngIf='user.message && !user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" routerLink="/message" routerLinkActive="active">MESSAGES</a>
				</li> -->
				<li class="nav-item" *ngIf='user.livechat && !user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" routerLink="/chat" routerLinkActive="active">LIVE CHAT</a>
				</li>
				<li class="nav-item" *ngIf='user.pod_finder'>
				  <a class="nav-link" href="{{auth.baseUrl}}/pod-finder/" routerLinkActive="active">POD FINDER</a>
				</li>
				<li class="nav-item" *ngIf='user.order_finder'>
				  <a class="nav-link" href="{{auth.baseUrl}}/order-finder/" routerLinkActive="active">ORDER FINDER</a>
				</li>
				<!-- <li class="nav-item" *ngIf='user.companies.account == "CLEA100"'>
				  <a class="nav-link" href="{{auth.baseUrl}}/order-finder/" routerLinkActive="active">POD FINDER</a>
				</li> -->
				<!-- <li class="nav-item" *ngIf='user.cad_only'>
				  <a class="nav-link" routerLink="/cad" routerLinkActive="active">CAD MSGS</a>
				</li> -->
				<!-- <li class="nav-item" *ngIf='user.role == "Employee" && !user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" href="{{auth.baseUrl}}/reports/0/">REPORTS</a>
				</li> -->
				<li class="nav-item" *ngIf='user.role == "Employee" && user.reports'>
				  <a class="nav-link" aria-current="page" routerLink="/reports" [routerLinkActiveOptions]="{exact: true}" routerLinkActive #rla="routerLinkActive" [ngClass]="[rla.isActive == true ? 'active': '']">FAST REPORTS</a>
				</li>
				<li class="nav-item" *ngIf='!user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" href="{{auth.baseUrl}}/tips/">SPEED TIPS</a>
				</li>
				<li class="nav-item" *ngIf='!user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" href="{{auth.baseUrl}}/lead/">LEAD TIME</a>
				</li>
				<li class="nav-item" *ngIf='user.role == "Employee" && !user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" href="{{auth.baseUrl}}/nqs/">NQS</a>
				</li>
				<li class="nav-item" *ngIf='!user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" href="{{auth.baseUrl}}/faq/">FAQ</a>
				</li>
				<li class="nav-item" *ngIf='user.role == "Employee" && user.score && !user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" routerLink="/score" routerLinkActive="active">LIVE SCORE</a>
				</li>
				<li class="nav-item" *ngIf='user.role == "Employee" && user.rating && !user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link" routerLink="/ratings" routerLinkActive="active">RATINGS</a>
				</li>
				<!-- <li class="nav-item" *ngIf='user.role == "Employee" && user.response'>
				  <a class="nav-link" routerLink="/response" routerLinkActive="active">AVG. RESPONSE</a>
				</li> -->
				<li class="nav-item" *ngIf='!user.cad_only && !user.cadrk_edit_only && !user.cadrk_view_only && !user.cadrk_edit_van_only'>
				  <a class="nav-link"><mat-slide-toggle labelPosition="before" [(ngModel)]="notify" (change)="onToggle($event)">Notifications</mat-slide-toggle></a>
				</li>
				<li class="nav-item">
					<button mat-button [matMenuTriggerFor]="beforeMenu" style="color: #fff;">{{user.first_name}} {{user.last_name}} <mat-icon>more_vert</mat-icon></button>
					<mat-menu #beforeMenu="matMenu" xPosition="before">
						<a mat-menu-item href="{{auth.baseUrl}}/profile/">PROFILE</a>
						<!-- <button mat-menu-item routerLink="/">DASHBOARD</button> -->
						<!-- <button mat-menu-item routerLink="/message">NEW CHAT</button> -->
						<a mat-menu-item href="{{auth.baseUrl}}/change-password/">CHANGE PASSWORD</a>
						<mat-divider></mat-divider>
						<button mat-menu-item (click)="logout()">LOGOUT</button>
					</mat-menu>
				</li>
			  </ul>
			</div>
		  </div>
		</nav>
	</div>
</header>