<mat-card class="orderlist">
	<mat-card-header>
		<mat-card-title>ORDER NUMBERS</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div class="filters">
			<div class="row">
				<div class="col-md-8" *ngIf='cad'>
					<div *ngIf='user.role == "Employee"' style="background-color: #58595b;color: #fff;padding: 5px;border-radius: 5px;margin-right: -15px;font-size: 12px;">Bulk Colour Code Change: <button mat-raised-button (click)="changeStatus()" style="margin-left: 5px;padding: 0 5px;line-height: 2;background: red;color: #fff;">COLOUR CODE</button></div>
					<div *ngIf='user.role == "Employee" && user.is_staff && user.companies.account == "CLEA100"' style="background-color: #58595b;color: #fff;padding: 10px;border-radius: 5px;margin-right: -15px;margin-top:5px;font-size: 12px;">Bulk Messaging: <button mat-raised-button (click)="bulkMsg()" style="margin-left: 5px;margin-top: -5px;padding: 0 5px;line-height: 2;background: red;color: #fff; float: right;">Edit & Send</button></div>
					<!-- <mat-form-field appearance="outline" class="w100">
						<mat-label>Search Orders...</mat-label>
						<input matInput type="text" [(ngModel)]="search" autocomplete="off">
						<mat-icon matSuffix>search</mat-icon>
					</mat-form-field> -->
				</div>
				<div class="col-md-2" *ngIf='cad'>
					<div class="rdunrd">
						<span>{{orders | filterPipe:search:'reference' | filterArray:status:'status' | filterArray:tag:'tag' | filterArray:group:'company' | filterArray:company:'company' | filterPipe:'true':'count'}}</span>
						<mat-icon aria-hidden="false" aria-label="email read icon" (click)="readCount()" [ngClass]="{ 'active': read == true }">mark_email_read</mat-icon>
					</div>
				</div>
				<div class="col-md-2" *ngIf='cad'>
					<div class="rdunrd">
						<span>{{orders | filterPipe:search:'reference' | filterArray:status:'status' | filterArray:tag:'tag' | filterArray:group:'company' | filterArray:company:'company' | filterPipe:'false':'count'}}</span>
						<mat-icon aria-hidden="false" aria-label="email unread icon" (click)="unreadCount()" [ngClass]="{ 'active': unread == true }">mark_email_unread</mat-icon>
					</div>
					<!-- <mat-icon aria-hidden="false" aria-label="email unread icon" (click)="refresh()">refresh</mat-icon> -->
				</div>
			</div>

			<!-- <section class="w100" style="text-align: center;">
				<mat-checkbox class="example-margin" [(ngModel)]="read"> Read({{orders | filterPipe:search:'reference' | filterArray:status:'status' | filterArray:tag:'tag' | filterArray:group:'company' | filterArray:company:'company' | filterPipe:'true':'count'}}) </mat-checkbox>
				<mat-checkbox class="example-margin" [(ngModel)]="unread"> Unread({{orders | filterPipe:search:'reference' | filterArray:status:'status' | filterArray:tag:'tag' | filterArray:group:'company' | filterArray:company:'company' | filterPipe:'false':'count'}})</mat-checkbox>
			</section> -->

			<mat-form-field appearance="outline" class="w100">
				<mat-label>Filter Status</mat-label>
				<mat-select [(value)]="status" multiple (selectionChange)="statusFilter()">
					<!-- <mat-option value="">---- Filter Status ----</mat-option> -->
					<mat-option value="Received">Received</mat-option>
					<mat-option value="Production">Production</mat-option>
					<mat-option value="Despatch">Despatch</mat-option>
					<mat-option value="Distribution">Distribution</mat-option>
					<mat-option value="On Its Way">On Its Way</mat-option>
					<mat-option value="Delivered">Delivered</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- <mat-form-field appearance="outline" class="w100" *ngIf='user.role == "Employee"'>
				<mat-label>Filter Distributors Groups</mat-label>
				<mat-select [(value)]="group" (selectionChange)="groupFilter()">
					<mat-option [value]="''">-- SELECT GROUP --</mat-option>
					<mat-option *ngFor="let group of groups" [value]="group.company">{{group.title}}</mat-option>
				</mat-select>
			</mat-form-field> -->
			
			<mat-form-field appearance="outline" class="w100" *ngIf='user.role == "Employee" && cad'>
			  <mat-label>Filter Distributors</mat-label>
			  <mat-chip-list #chipList aria-label="Distributor selection">
			    <mat-chip
			      *ngFor="let dist of companies"
			      [selectable]="selectable"
			      [removable]="removable"
			      (removed)="remove(dist)">
			      {{dist.account}}
			      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
			    </mat-chip>
			    <input
			      placeholder="Filter Distributor..."
			      #fruitInput
			      [formControl]="fruitCtrl"
			      [matAutocomplete]="auto"
			      [matChipInputFor]="chipList"
			      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			      (matChipInputTokenEnd)="add($event)">
			  </mat-chip-list>
			  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
			    <mat-option *ngFor="let distributor of filteredCompanies | async" [value]="distributor">
			      {{distributor.account}}
			    </mat-option>
			  </mat-autocomplete>
			</mat-form-field>

			<!-- <mat-form-field appearance="outline" class="w100" *ngIf='user.role == "Employee"'>
				<mat-label>Filter Distributors</mat-label>
				<mat-select [(value)]="company" multiple (selectionChange)="companyFilter()">
					<mat-option *ngFor="let company of distributors" [value]="company.id">{{company.account}}</mat-option>
				</mat-select>
			</mat-form-field> -->
			<mat-form-field appearance="outline" class="w100">
				<mat-label>Filter Colour Code</mat-label>
				<mat-select [(value)]="tag" multiple (selectionChange)="tagFilter()">
					<mat-option *ngFor="let tag of tags" [value]="tag.id">{{tag.title}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<mat-list>
		  <mat-list-item *ngFor="let order of orders | filterPipe:search:'reference' | filterArray:status:'status' | filterPipe:read:'read' | filterPipe:unread:'unread' | filterArray:tag:'tag' | filterArray:group:'company' | filterArray:company:'company' | sortBy:'pinned'; index as i" [ngClass]="{ 'active': order == current }">
		  	<mat-checkbox *ngIf='user.role == "Employee"' class="example-margin" [checked]="allComplete" (change)="setOrder($event.checked, order.id)"></mat-checkbox>
			<strong style="background-color: {{order.tags.code}}; width: 10px; height: 10px;margin-right: 5px;"></strong>
			<strong (click)="orderCall(order, i)">{{order.reference}}</strong> | <span (click)="orderCall(order, i)">{{order.sale}}</span>
			<mat-icon style="margin-right: 20px;transform: rotate(45deg);" aria-hidden="false" aria-label="PIN" *ngIf="order.pinby | filterArray:user.id:'pinme'">push_pin</mat-icon> 
			<mat-icon aria-hidden="false" aria-label="Notification" *ngIf="order.read == false">notification_add</mat-icon>
		  </mat-list-item>
		</mat-list>
	</mat-card-content>
	<mat-card-actions>
		<!-- <button mat-raised-button (click)="more(next)" class="more" *ngIf='remaining > 0'>LOAD MORE ({{remaining}})</button> -->
		<!-- <button mat-button>SHARE</button> -->
	</mat-card-actions>
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner>
</mat-card>