<main>
	<app-topnav></app-topnav>
	<section class="container maintenance" *ngIf="maintenance">
		<div><img src="assets/images/503.png" alt="MyClearAmber"></div>
		<h1>{{maintdata.title}}</h1>
		<h3>{{maintdata.message}}</h3>
	</section>
	<section class="container rkdash" *ngIf="!maintenance">
		<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
		
		<div class="filtration" style="margin-top:10px;">
			<div class="row">
				<div class="col-md-3">
					<mat-form-field appearance="outline" class="w100">
						<mat-label>Area:</mat-label>
						<mat-select [(ngModel)]="area" (selectionChange)="areaFilter()">
							<mat-option [value]="''">-- SELECT AREA --</mat-option>
							<mat-option *ngFor="let area of areas" [value]="area">{{area.title}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field appearance="outline">
		                <mat-label>Despatch Date:</mat-label>
		                <input matInput [matDatepicker]="picker" [(ngModel)]='date' (dateChange)="dchange($event)">
		                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		                <mat-datepicker #picker></mat-datepicker>
		            </mat-form-field>
				</div>
				<div class="col-md-6"></div>
			</div>
		</div>
	</section>
	<section class="container-fluid rkdash" *ngIf="!maintenance">
		<div class="rkdata">
			<div class="row">
				<div class="col-md-8">
					<div class="row">
						<div class="col-md-2">
							<div>
								<p>No. of CONS</p>
								<p><strong>{{total}}</strong></p>
							</div>
						</div>
						<div class="col-md-3">
							<div>
								<p>No. Scanned To Van <strong>{{scanned}}</strong></p>
								<p style="margin-top: 20px;">% Scanned to Van <strong>{{scannedp}}%</strong></p>
							</div>
						</div>
						<div class="col-md-3">
							<div>
								<p>No. Scanned Delivered  <strong>{{delivered}}</strong></p>
								<p style="margin-top: 20px;">% Scanned Delivered <strong>{{deliveredp}}%</strong></p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div style="font-size:14px">Total CONS To Be Delivered Today</div>
							<div style="font-size:50px;margin: 20px 0;font-weight: bold;">{{rkcpr.vanallot}}</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div style="font-size:14px">No of Vans Being Used Today</div>
							<div style="font-size:50px;margin: 20px 0;font-weight: bold;">{{rkcpr.vancount}}</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-8">
							<div id="cprChart" style="border: 1px solid #F38E2C;"></div>
						</div>
						<div class="col-md-4" style="font-weight: bold;">
							<div style="padding:20px 0;text-align: center;margin: 30px auto;font-size: 80px;">CPR</div>
							<div style="padding:20px 0;text-align: center;background-color: {{rkcpr.cprcolour}};">
								<p style="font-size: 100px; padding: 30px 0;font-size: 100px;">{{rkcpr.cpr}}</p>
								<p style="font-size: 35px;">{{rkcpr.word}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="container rkdash" *ngIf="!maintenance">
		<div class="rkdash">
			<div class="row">
				<div class="col-md-6" *ngFor="let van of vans" [ngClass]="{'hidevan':van.rkdata.length < 1}">
					<div class="vanimg">
						<div class="vgrid">
							<div class="order" *ngFor="let order of van.rkdata">
								<p class="redc" [ngClass]="{'ylow':order.scanned == true, 'grnc':order.delivered == true}">{{order.sale}} {{order.postcode}}</p>
								<!-- <a href="{{auth.baseUrl}}/pod/{{order.orders?.identifier}}/" target="_blank" class="redc" [ngClass]="{'ylow':order.scanned == true, 'grnc':order.delivered == true}">{{order.sale}} {{order.postcode}}</a> -->
							</div>
							<div class="order" *ngFor="let item of [].constructor(30-van.rkdata.length); let i = index"></div>
							<!-- <table class="table table-bordered table-sm">
								<tbody>
									<tr><td>123</td><td>123</td><td>123</td><td>123</td><td>123</td></tr>
									<tr><td>123</td><td>123</td><td>123</td><td>123</td><td>123</td></tr>
									<tr><td>123</td><td>123</td><td>123</td><td>123</td><td>123</td></tr>
									<tr><td>123</td><td>123</td><td>123</td><td>123</td><td>123</td></tr>
									<tr><td>123</td><td>123</td><td>123</td><td>123</td><td>123</td></tr>
								</tbody>
							</table> -->
						</div>
						<div class="vanm">
							<div class="vtitle">{{van.title}}</div>
							<div class="varea">{{van.areas.title}}</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-md-6"><img src="assets/images/Clear-Amber-Luton-Van-V1.02.png" alt="MyClearAmber" width="100%"></div> -->
			</div>
		</div>
	</section>
</main>