<div class="bulk">
	<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#F38E2C" type = "ball-clip-rotate-pulse" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

	<div class="row">
		<!-- <div class="col-md-12">
			<img src="assets/images/nqs.png" alt="NQS" class="img-fluid">
		</div> -->
		<div class="col-md-12 heading">
			<h2>Bulk Messaging</h2>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-12">
			<mat-form-field appearance="outline" class="w100">
				<mat-label>Type your message here…</mat-label>
				<textarea matInput placeholder="Type your message here…" [(ngModel)]="message"></textarea>
			</mat-form-field>
			<mat-form-field appearance="outline" class="w100">
				<mat-label>Qty or Orders Selected:</mat-label>
				<input matInput disabled [value]="orders.length">
			</mat-form-field>
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-md-4"><button mat-raised-button (click)="cancel()">CANCEL</button></div>
		<div class="col-md-2"></div>
		<div class="col-md-6"><button mat-raised-button (click)="send()" color="warn">Send To All Selected</button></div>
	</div>
</div>